<template>
    <div class="step-prescription" :class="{ active: step == 3, next: step == 2, prev: step == 4 }">
        <h3>Affinement de vos verres</h3>

        <template v-for="refinement in refinements" :key="refinement.id" >
           <div class="item" @click="chooseRefinement(refinement.id, refinement.price)">
            <span class="name">{{ refinement.name }}</span>
            <span class="decription">{{ refinement.description }}</span>
            <span class="price"><span v-if="refinement.price > 0">{{ refinement.price }}€</span><span
                    v-else>Offert</span></span>
          </div>
          <br>
        </template>
    </div>
</template>

<script>
export default {
    name: "OptionThinned",
    props: ["step","refinements"],
    methods: {
        chooseRefinement(id,price)
        {
            this.$emit('chooseRefinementEv', id, price)
        }
    }

}
</script>

<style scoped>

.item {
  cursor: pointer;
}
.item .description {
  margin-right: 10px;
}

.item .price {
  float: right;
}

.item .name {
  font-weight: 700;
  display: block;
}

</style>