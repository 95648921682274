<template>
    <div id="cart-icon_cart" v-if="loaded">
        <img src="../../../../public/assets/images/cartnew.svg" style="float:left;" width="20">
        <span id="number_items_cart">{{ cart_item_nb }}</span>

        <span class="contenumob mob-cart-sentense" style="float:left;">
            <span id="number_items_cart_2">{{ cart_item_nb }}</span>
            article(s)
        </span>
    </div>
</template>

<script>
export default {
    name: "CartIcon",
    props: ["cart_item_nb", "loaded"],
}
</script>

<style>

</style>