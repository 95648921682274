<template>
    <div class="right">
      <div >
        <div v-if="language=='fr'" class="title">TÉLÉCHARGER<br /><span class="outline">L'APP</span></div>
        <div v-else class="title">GET <span class="outline">THE APP</span></div>
          
            <div v-if="language=='fr'" class="button-white">
              <a href="https://apps.apple.com/fr/app/visages/id1632011793" target="_blank"><img src="../../../../public/assets/images/apple.svg" width="25" /> <span>Télécharger sur l'App Store</span>
              </a></div>
            
            <div  v-else class="button-white">
              <a href="https://apps.apple.com/fr/app/visages/id1632011793" target="_blank">

              <img src="../../../../public/assets/images/apple.svg" width="25" /> <span>Download on
                        the App Store</span>
                      </a>
            </div>
                      
       
        </div>
        
        <div class="content">
            <img src="../../../../public/assets/images/qr-download.png" class="qr" />

           
        </div>


    </div>
</template>

<script>
export default {
    name: "FooterGetApp",
    props:["language"]
}
</script>

<style scoped>

#site-footer .right .title {
  font-size:80px;
  text-align: center;
  font-weight: 600;
  margin-bottom:0px;
  white-space: nowrap;
}

#site-footer .top-footer .right .qr {
  width:60%;
  display:block;
  margin-left:15%;
  max-width: 320px;
}
#site-footer .top-footer .right
{
  width:55%;
  text-align: center;
  padding-bottom:50px;
  display:flex;
}
#site-footer  .top-footer .right .content {
  text-align: center;
}
  #site-footer .top-footer .right {
  padding-top:0;
}

.button-white img {
    display:inline-block;
  }
  .button-white {
    margin-top: 20px;
    color: #000;
    background: #fff;
    display: inline-block;
    padding: 8px 40px;
    border-radius: 40px;
  }
  .button-white span {
    position:relative;
    color:#000;
    top:-5px;
    font-size:20px;
    
    margin-left:10px;
  }

</style>