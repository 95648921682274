<template>
  <div class="bottom-bar">
    <div class="cross" @click="closeBar()"><img src="../../../public/assets/images/cross2.svg" width="10" /></div>
    <div class="icon"></div>
    <div class="text">
      <span class="title">Télécharger l'application VISAGES</span><br />
      Afin d’ajuster cette monture sur-mesure nous scannons votre visage avec notre application.
    </div>
    <div class="button-right">
      <a href="#">Ouvrir</a>
    </div>
  </div>
</template>


<script>
import emitter from 'tiny-emitter/instance'

export default {
  components: {  },
  name: 'MobileBottomBar',
  props:['displayed'],
  data() {
    return {
    
    }
  },
  async mounted(){

  },
  methods:{
      closeBar() {
        emitter.emit('closeMobileBottomBar')
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-right a
{
  background: rgba(0,0,0,0.1) !important;
  border-radius:16px !important;
  font-size:12px !important;
  font-weight:600 !important;
  color: #5186EC !important;
  padding:8px 10px !important;
  text-decoration: none !important;
}
.button-right {
  width:18% !important;
  text-align:center !important;
}
.text {
  width:57% !important;
  font-size:10px !important;
  padding-left:10px !important;
  padding-right:10px !important;
}
.text .title {
  color:#000 !important;
  font-weight: 600 !important;
  font-size:13px !important;
}
.bottom-bar {
  display:flex !important;
  border-top:1px solid #939393 !important;
  border-bottom:1px solid #939393 !important;
  background:#f5f5f5 !important;
  position:fixed !important;
  bottom:0 !important;
  left:0 !important;
  z-index:18 !important;
  width:100% !important;
  padding-top:10px !important;
  padding-bottom:10px !important;
  justify-content: center !important;
  align-items: center !important;
}
.icon {
  border:1px solid #939393 !important;
  background-position:center center !important;
  background-size:cover !important;
  border-radius:8px !important;
  width:18% !important;
  height:18vw !important;
  background-image: url(../../../public/assets/images/app.png) !important;
}
.cross img {
  display: inline-block !important;
}
.cross {
  width:7% !important;
  text-align:center !important;
}
@media screen and (min-width:760px) {
  .bottom-bar {
    display: none !important;
  }
}
</style>
