const collection = {

    name: 'Collection',
    name_en: 'Collection',
    array: [
        // {
        //     link: "collection",
        //     txt: "Solaires",
        //     txt_en: "Solar"
        // },
        // {
        //     link: "collection",
        //     params:{toggle:'optic'},
        //     txt: "Optiques",
        //     txt_en: "Optic"
        // },
        {
            link: "collection-beta",
            txt: "BETA",
            txt_en: "BETA"
        },
        // {
        //     link: "collection",
        //     txt: "ANTI LUMIÈRE BLEUE"
        // },
        // {
        //     link: "accessories",
        //     txt: "ACCESSOIRES"
        // }

    ]
}

const collaboration = {

    name: '+ COLLABORATIONS',
    array: [
        {
            link: "collaboration",
            txt: "VISAGES X REVEUR LUCIDE"
        }
    ]
}

const concept = {

    name: 'Modèles',
    name_en: 'Models',
    array: [
        // {
        //     link: "collection-glass-color-type",
        //     params:{id:22,type:'solar'},
        //     txt: "Chaï",
        //     txt_en: "Chaï"
        // },
        // {
        //     link: "collection-glass",
        //     params:{id:27},
        //     txt: "Louis C",
        //     txt_en: "Louis C"
        // },
        // {
        //     link: "collection-glass",
        //     params:{id:28},
        //     txt: "Louis S",
        //     txt_en: "Louis S"
        // },
        {
            link: "collection-beta",
            params:{},
            txt: "Chaï",
            txt_en: "Chaï"
        },
        {
            link: "collection-beta",
            params:{},
            txt: "Louis C",
            txt_en: "Louis C"
        },
        {
            link: "collection-beta",
            params:{},
            txt: "Louis S",
            txt_en: "Louis S"
        },
       
    ]
}

const application = {

    name: 'APPLICATION MOBILE',
    array: [
        {
            link: "download",
            txt: "TELECHARGEMENT APP IOS"
        }
    ]
}

export default {
    menu: [[collection, concept]],
    menuMob: {collection, collaboration, concept, application}
}
