export default {
    items: [
        
       
        {
            txt:"Politique de confidentialité",
            txt_en:"Private policy",
            link:"confidentiality", 
        },
        {
            txt:"Livraison + Suivi",
            txt_en:"Delivery + Shipping",
            link:"cgv", 
        },
         {
            txt:"CGV",
            txt_en:"Conditions",
            link:"cgv", 
        },
        // {
        //     txt:"Langues",
        //     txt_en:"Languages",
        //     link:"#", 
        // },
    ]
}