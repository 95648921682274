<template>
  <div id="popup-prescription" class="popup-login">

    <div class="content">
      <div class="back-step" @click="previousStep()"></div>

      <span id="cross" @click="closePrescription()"><img
          src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
      <content-loader class="the-content" v-if="loaded == false" viewBox="0 0 520 250" primaryColor="#f3f3f3"
        secondaryColor="#cccccc">
        <rect x="0" y="20" rx="11" ry="11" width="520" height="22" />
        <rect x="0" y="70" rx="11" ry="11" width="520" height="22" />
        <rect x="0" y="120" rx="11" ry="11" width="520" height="22" />

      </content-loader>
      <div class="the-content" v-else>
        <div class="step-prescription" :class="{ active: step == 1, prev: step == 2 }">
          <h3>Votre ordonnance</h3>
          <div v-if="prescriptions.length > 0" class="prescription-option" @click="changeStep(2, 'account')">Je choisis
            l'ordonnance depuis mon compte</div>
          <div @click="changeStep(2, 'custom')" class="prescription-option">Renseigner ma correction</div>
          <div class="prescription-option" @click="changeStep(2, 'file')">Je télécharge mon ordonnance</div>

          <div @click="after()" v-if="cart_item != null && prescription_order == false" class="prescription-option">
            Renseigner plus tard
            <div class="description">Après paiement</div>
          </div>
        </div>
        <div class="step-prescription"
          :class="{ active: step == 2 && currentOption == 'file', next: step == 1, prev: step == 3 && currentOption == 'file' }">
          <h3>Votre ordonnance</h3><br /><br />
          <label>Choisissez un fichier à envoyer</label>
          <input type="file" @change="fileAdded($event)" /><br /><br />
          <label>Donnez un nom à votre ordonnance</label>
          <input type="text" v-model="form.name" /><br /><br />
          <button @click="sendPrescriptionFile()">Envoyer mon ordonnance</button>
          <br /><br />
        </div>

        <OptionAccount v-if="step == 2 && currentOption == 'account'" :prescriptions="prescriptions"
          @choosePrescriptionEv="choosePrescription" />

        <div class="step-prescription"
          :class="{ active: step == 2 && currentOption == 'custom', prev: step == 3 && currentOption == 'custom' }">
          <h3>Votre ordonnance</h3>

          <template v-for="(eye, index) in eyes" :key="index">
          <OptionCustom :eye="eye" :step="step" :currentOption="currentOption" :form="form"
            @sphereChangeEv="sphereChange" @cylinderChangeEv="cylinderChange" @axisChangeEv="axisChange"
            @spdistChangeEv="spdistChange" @pdistChangeEv="pdistChange" @prismPosChangeEv="prismPosChange"
            @prismStrChangeEv="prismStrChange" @changeFormNameEv="changeFormName" @checkItemEv="checkItem"
            @sendPrescriptionEv="sendPrescription" />
            </template>

          <label>Donnez un nom à votre ordonnance</label>
          <input type="text" v-model="form.name" />
          <button @click="sendPrescription()">Envoyer ma correction</button>

        </div>
        <OptionThinned :step="step" :refinements="refinements" @chooseRefinementEv="chooseRefinement" />
        <OptionTreatment :step="step" :processings="processings" @closePrescriptionEv="closePrescription" />

      </div>

      <div class="bar" :style="'width:' + barPercent + '%;'">

      </div>
      <span class="price_total"><span class="total-label">Total</span> {{ current_price }}€</span>
    </div>
  </div>
</template>


<script>
import { Auth, APIRequest } from "@simb2s/senseye-sdk";
import jQuery from 'jquery';
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader'

// Import Children
import OptionAccount from "../children/Prescriptions/OptionAccountComponent.vue"
import OptionCustom from "../children/Prescriptions/OptionsCustomComponent.vue"
import OptionThinned from "../children/Prescriptions/OptionThinnedComponent.vue"
import OptionTreatment from "../children/Prescriptions/OptionTreatmentComponent.vue"


export default {
  name: 'PopupApplication',
  components: {
    ContentLoader,
    OptionAccount,
    OptionCustom,
    OptionThinned,
    OptionTreatment,

  },
  data() {
    return {
      step: 1,
      prescription_id: null,
      current_price: 0,
      actualRefinement: null,
      loaded: false,
      actualProcessing: null,
      actualRefinementPrice: 0,
      actualPrescription: null,
      actualProcessingPrice: 0,
      refinements: [],
      processings: [],
      prescriptions: [],
      barPercent: 25,
      currentOption: null,
      cart_item_form: null,
      eyes: ["right", "left"],
      form: {
        name: '',
        file: '',
        right_sphere: '0',
        right_cylinder: '0',
        right_addition: '',
        right_axis: '',
        left_sphere: '0',
        left_cylinder: '0',
        left_addition: '',
        left_axis: '',
        left_pupillary_distance: '',
        right_pupillary_distance: '',
        pupillary_distance: '',
        left_prism: '',
        right_prism: '',
        prism: false,
        full_pupillary_distance: false,
        semi_pupillary_distance: true,
        user: null,
      }
    }
  },
  props: ['cart_item', 'prescription_order'],

  async mounted() {

    await this.getRefinements();
    await this.getProcessings();

    this.cart_item_form = await APIRequest.detail('order-items', this.cart_item)
    this.current_price = this.cart_item_form.amount

    if (this.cart_item_form.scan != null) {

      if (this.cart_item_form.scan.measures_url) {
        fetch(this.cart_item_form.scan.measures_url
          , {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (myJson) {

            let pd = (Math.round(myJson.PupillaryDistance * 2) / 2);
            this.pupillary_distance = String(pd)
          });
      }


    }

    if (localStorage.token_senseye_auth) {

      await this.getPrescriptions();
    }

    this.loaded = true
  },
  methods: {
    sphereChange(eye, value) {
      if (eye === 'right') {
        this.form.right_sphere = value
      } else {
        this.form.left_sphere = value;
      }
    },
    cylinderChange(eye, value) {
      if (eye === 'right') {
        this.form.right_cylinder = value
      } else {
        this.form.left_cylinder = value;
      }
    },
    axisChange(eye, value) {
      if (eye === 'right') {
        this.form.right_axis = value
      } else {
        this.form.left_axis = value;
      }
    },
    spdistChange(eye, value) {
      if (eye === 'right') {
        this.form.right_pupillary_distance = value
      } else {
        this.form.left_pupillary_distance = value
      }
    },
    prismPosChange(eye, value) {
      if (eye === 'right') {
        this.form.right_prism = value
      } else {
        this.form.left_prism = value
      }
    },
    prismStrChange(eye, value) {
      if (eye === 'right') {
        this.form.right_addition = value
      } else {
        this.form.left_addition = value
      }
    },
    pdistChange(value) {
      this.form.pupillary_distance = value
    },
    after() {
      this.step = 3
      //emitter.emit('close-prescription-popup')
    },
    async getPrescriptions() {

      let user = this.parseJwt(localStorage.token_senseye_auth);
      let user_data = await Auth.get_user(user.username)
      this.form.user = {}
      this.form.user.id = user_data.id
      let prescriptions = await APIRequest.list('prescriptions', user_data.id)
      this.prescriptions = prescriptions[0];
    },
    parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    async loginUser(username, password) {
      let user = emitter.emit('login', username, password)

      if (user == null) {
        this.errorLogin = 'Vos identifiants sont incorrects.';
      }

    },
    async choosePrescription(prescription) {

      this.actualPrescription = prescription
      let cart_item = this.cart_item_form
      cart_item.prescription = {}
      cart_item.prescription.id = prescription
      this.cart_item_form = await APIRequest.update('order-items', this.cart_item_form.id, cart_item)
      if (this.prescription_order == true) {
        emitter.emit('close-prescription-popup')
      }
      else {
        this.step = 3
        this.barPercent = this.step * 25;
      }

    },
    fileAdded(event) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {

        let b64 = reader.result.split(';')
        let type = b64[0]

        let base64 = b64[1]
        //base64.replace('base64,','')
        this.form.file = reader.result
      };
    },

    async getRefinements() {
      let refinements = await APIRequest.list('refinements')
      this.refinements = refinements[0];
    },
    async getProcessings() {
      let processings = await APIRequest.list('processings')
      this.processings = processings[0];
    },
    checkItem(item) {
      if (item == "prism") {
        if (this.form.prism == false) {
          this.form.prism = true
        }
        else {
          this.form.prism = false
        }
      }
      if (item == "full_pupillary_distance") {
        if (this.form.full_pupillary_distance == false) {
          this.form.full_pupillary_distance = true
          this.form.semi_pupillary_distance = false
        }
        else {
          this.form.full_pupillary_distance = false
          this.form.semi_pupillary_distance = true
        }
      }
      if (item == "semi_pupillary_distance") {
        if (this.form.semi_pupillary_distance == false) {
          this.form.semi_pupillary_distance = true
          this.form.full_pupillary_distance = false
        }
        else {
          this.form.semi_pupillary_distance = false
          this.form.full_pupillary_distance = true
        }
      }
    },
    async sendPrescriptionFile() {

      if (this.form.file != '' || this.form.name != '') {
        if (this.prescription_id != null) {
          let prescription = await APIRequest.update('prescriptions', this.prescription_id, this.form)
        }
        else {
          let prescription = await APIRequest.create('prescriptions', this.form)
          this.prescription_id = prescription.id
          let cart_item = this.cart_item_form
          cart_item.prescription = {}
          cart_item.prescription.id = prescription.id
          this.cart_item_form = await APIRequest.update('order-items', this.cart_item_form.id, cart_item)
        }


        if (this.prescription_order == true) {
          emitter.emit('close-prescription-popup')
        }
        else {
          this.step = 3
          this.barPercent = this.step * 25;
        }
      }
      else {
        alert('Vous devez sélectionner un fichier et renseigner un nom !')
      }
    },
    async sendPrescription() {
  
      if (this.form.name != "" && this.form.right_sphere != "" && this.form.right_cylinder != "" && this.form.right_axis != "" && this.form.left_sphere != "" && this.form.left_cylinder != "" && this.form.left_axis != "") {
        if (this.prescription_id != null) {
          let prescription = await APIRequest.update('prescriptions', this.prescription_id, this.form)
        }
        else {
          let prescription = await APIRequest.create('prescriptions', this.form)
          this.prescription_id = prescription.id
          let cart_item = this.cart_item_form
          cart_item.prescription = {}
          cart_item.prescription.id = prescription.id
          this.cart_item_form = await APIRequest.update('order-items', this.cart_item_form.id, cart_item)
        }

        if (this.prescription_order == true) {
          emitter.emit('close-prescription-popup')
        }
        else {
          this.step = 3
          this.barPercent = this.step * 25;
        }
      }
      else {
        alert('Certains champs sont manquants !')
      }

    },
    previousStep() {
      if (this.step != 1) {
        this.step -= 1
      }

    },
    chooseRefinement(refinement, price) {
      this.step = 4
      this.barPercent = this.step * 25;
    
      this.actualRefinement = refinement
      this.actualRefinementPrice = price
      this.current_price = this.cart_item_form.amount * 1 + this.actualRefinementPrice * 1
    },
    changeStep(step, option) {
      this.step = step
      this.currentOption = option
      this.barPercent = this.step * 25;
    },
    async closePrescription(processing = null, price = null) {
     
      this.loaded = false
      if (processing != null) {
        this.actualProcessing = processing
        this.actualProcessingPrice = price
        this.current_price = this.cart_item_form.amount * 1 + this.actualRefinementPrice * 1 + this.actualProcessingPrice * 1
        let cart_item = this.cart_item_form
        cart_item.processing = {}
        cart_item.processing.id = processing
        cart_item.refinement = {}
        cart_item.refinement.id = this.actualRefinement
        cart_item.amount = this.current_price
      
        this.cart_item_form = await APIRequest.update('order-items', this.cart_item_form.id, cart_item)
        emitter.emit('display-cart')
      }
      else {
        await APIRequest.delete('order-items', this.cart_item_form.id)
      }
      emitter.emit('update-cart')
      emitter.emit('close-prescription-popup')
    },
    closeModal($event) {
      if (jQuery($event.target).parents('.content').length) {

      }
      else {
        emitter.emit('close-prescription-popup')
      }
    },
  },
}
</script>

<style scoped>
.step-prescription.active {
  display: block;

  top: 50%;
  transform: translateY(-50%);
  animation: animsep 0.4s ease;
}

.step-prescription {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.step-prescription.prev {
  position: fixed;
  display: block;
  opacity: 0.4;
  right: 5%;
  left: auto;
  pointer-events: none;
  transform: translateY(-100%);
  margin-top: 100px;
  top: 0vh;
  width: 35%;
}

.step-prescription.next {
  position: fixed;
  display: block;
  opacity: 0.4;
  right: 5%;
  left: auto;
  pointer-events: none;
  transform: translateY(-100px);
  top: 100vh;
  width: 35%;
}

@keyframes animsep {

  from {
    opacity: 0.4;
    transform: translateY(140%);
  }

  to {
    opacity: 1;
    transform: translateY(-50%);
  }

}

.prescription-option .description {
  font-size: 12px;
}

.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }

.item:hover {
  background: rgba(0, 0, 0, 0.1);
}

.back-step {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  height: 50px;
}

.item {
  cursor: pointer;
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  border: 0.30000001192092896px solid #707070;
}

.register-form input,
.login-form input,
.register-form select,
.login-form select {
  margin-bottom: 20px;
}

#popup-prescription .the-content {
  width: 80%;
  position: absolute;
  overflow: hidden;
  left: 10%;
  top: 10%;
  height: 80%;
}

#popup-prescription {
  z-index: 9999999;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0vh;
}

#popup-prescription .content {
  background: #fff;
  position: absolute;
  right: 0%;
  text-align: left;
  top: 0;
  min-width: 45%;
  height: 100%;
  padding: 5%;

}

#popup-prescription .content .left {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  padding-right: 10px;
}

#popup-prescription .content .right
    {
      
      display: inline-block;
      width:49%;
      vertical-align: top;
      padding-left:10px;
      text-align: left;
    }
#popup-prescription #cross {
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  left: 10px;
  right: auto;
  top: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

#popup-prescription .prescription-option:hover {
  background: rgba(0, 0, 0, 0.1);
}

#popup-prescription .prescription-option {
  background: #F8F8F8;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.5s;
}

#popup-prescription label {
  font-size: 12px;
}

#popup-prescription h4.pupillary {
  margin-bottom: 0;
}

#popup-prescription h4 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#popup-prescription h2,
#popup-prescription h3 {
  font-size: 23px;
  margin-top: 0;
  margin-bottom: 0;
}

#popup-prescription .bar {
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 40px;
  transition: all 0.5s;
  background: #000;
}

#popup-prescription input,
#popup-prescription select {
  padding: 10px 20px;
  font-size: 12px;
  width: 100%;
  margin-bottom: 10px;
}

div.checkbox.active::before {
  background: #000;
}

div.checkbox::before {
  content: ' ';
  position: absolute;
  left: 0;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 1px solid black;
}

div.checkbox {
  position: relative;
  padding-left: 20px;
}

@media screen and (max-width:760px) {
  body .step-prescription.prev {


    right: 10%;

    width: 80%;
  }

  body .step-prescription.next {

    right: 10%;

    width: 80%;
  }

  body #popup-prescription .price_total {
    right: 5%;
    left: auto;
    top: 20px;
    bottom: auto;
  }

  body #popup-prescription .bar {
    top: 60px;
    bottom: auto;
  }

  body #popup-prescription .content {
    padding-top: 100px;
    min-width: 100%;
  }

}

.price_total .total-label {
  font-size: 15px;
}

.price_total {
  position: absolute;
  right: 5%;
  bottom: 5px;
  font-size: 25px;
}

.loader {

  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 6;
}

.loader img {
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
