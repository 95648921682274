const formatObjectCollection = (product, options, cart) => {

    let price 
    let glass=product
    let id_glass=glass.id

    if(options.engraving_text!=null && options.engraving_text!="")
    {
        price = glass.price*1 + options.color_price*1 + options.tint_price*1 + options.texture_price*1 + options.branch_price*1 + options.glass_type_price*1 + options.glass_option_price*1 + 10*1
    }
    else
    {
        price = glass.price*1 + options.color_price*1 + options.tint_price*1 + options.texture_price*1 + options.branch_price*1 + options.glass_type_price*1 + options.glass_option_price*1
    }

    if(options.texture_style_price) {
        price+=options.texture_style_price
    }
    let cart_item = {};
    if(options.texture_style) {
        cart_item.texture_style=options.texture_style
    }
    cart_item.scan=options.scan
    cart_item.color = options.color;
    cart_item.glass_option = options.glass_option;
    cart_item.glass_type = options.glass_type;
    
    
    cart_item.lense="";
    cart_item.branch=options.branch;
    cart_item.finish="";
    cart_item.engraving_text=options.engraving_text;

    if(options.tint!=null)
    {
        if(options.tint.id!==null)
        {
        cart_item.tint=options.tint; 
        }
    }
    if(options.texture!=null)
    {
        if(options.texture.id!==null)
        {
            cart_item.texture = {}
        cart_item.texture=options.texture; 
        }
    }
   
    if(options.branch_color!=null)
    {
        if(options.branch_color.id!==null)
        {
        cart_item.branch_color=options.branch_color; 
        }
    }

    if(options.branch_texture!=null)
    {
        if(options.branch_texture.id!==null)
        {
        cart_item.branch_texture=options.branch_texture; 
        }
    }

    
    cart_item.quantity=1;
    cart_item.glass={};
    cart_item.glass.id=id_glass;
    cart_item.cart={};
    cart_item.cart.id=cart.id;
  
    cart_item.measurements="";
    cart_item.amount=price;
    cart_item.shapediver_id=0;
    cart_item.size=options.size
    cart_item.order_item_customizations=options.order_item_customizations;

    return cart_item;
}

module.exports = formatObjectCollection;