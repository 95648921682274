<template>
   <div class="image product-img">

        <content-loader  v-if="loading"
            viewBox="0 0 400 400"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="400" />
        
        </content-loader>
        <content-loader   v-else-if="photosLoaded==0"
            viewBox="0 0 400 400"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="400" />
        
        </content-loader>
        <content-loader class="loader-image"  v-else-if="(photosLoaded<photoDatas.length && cart_item.glass.id!=22) || (photosLoaded<photoDatas.length-1 && cart_item.glass.id==22)"
            viewBox="0 0 400 400"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="0" y="0" rx="0" ry="0" width="400" height="400" />
        
        </content-loader>
               
        <img v-for="(layer_info,index) in photoDatas" :key="index" :class="{'product-img-inner':index>0}" @load="photosLoaded+=1" :style="'opacity:'+getOpacityElement(layer_info)+';'" :src='calculatePhoto(layer_info)'
    
    />
   </div>
    
                        
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

import { APIRequest } from "@simb2s/senseye-sdk"
export default {
    name: "CartImageComponent",
    props: ["cart_item"],
    components: {
        ContentLoader
  },
    methods: {

        getBrightnessElement(layer_info) {
            if(layer_info.presets.brightness != null) {
                return layer_info.presets.brightness/100
            }
            else {
                return 1
            }
        },
        getHueElement(layer_info) {
            if(layer_info.presets.hue != null) {
                return layer_info.presets.hue/1.7
            }
            else {
                return 0
            }
        },
        getSaturationElement(layer_info) {
            if(layer_info.presets.saturation != null) {
                return layer_info.presets.saturation/100
            }
            else {
                return 1
            }
        },
        
        getOpacityElement(layer_info) {
            if(layer_info.presets.opacity != null) {
                return layer_info.presets.opacity/100
            }
            else {
                return 1
            }
        },

        async getModelParts(){

            let cart_item=this.cart_item

            let request_modelparts = '?glass='+cart_item.glass.id
            let model_partss =  await APIRequest.list_with_request('model-parts',request_modelparts)
            
            this.model_parts = model_partss[0]
        },
        async getRenderInfos(){
            let cart_item=this.cart_item
           
            let model_parts = this.model_parts
            
            let request='?glass='+cart_item.glass.id+'&branch=4'

            for(let i=0;i<model_parts.length;i++) {
       
                if(model_parts[i].render_type!="shadow" && model_parts[i].render_type!="hinge"){
                    if(model_parts[i].type=='color') {
                        if(model_parts[i].render_slug=="frame")
                        {
                        // ON parcoure les order cart_item customizations pour voir si il y a le meme id que le model part courant
                        let founded=false
                        // Si oui on precise le param
                            for(let k=0;k<cart_item.order_item_customizations.length;k++)
                            {
                                if(cart_item.order_item_customizations[k].model_part.id==model_parts[i].id)
                                {
                                    request+='&color_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].color.id
                                    request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                
                                //   params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                                //   params['texture_style_'+model_parts[i].render_slug]=0
                                founded=true
                                }
                                
                            }
                            if(founded==false) {
                                if(model_parts[i].material=="plastic")
                                {
                                    request+='&color_'+model_parts[i].render_slug+'=10'
                                //  params['color_'+model_parts[i].render_slug]=10
                                }
                                else {
                                    request+='&color_'+model_parts[i].render_slug+'=19'
                                //params['color_'+model_parts[i].render_slug]=23
                                }
                                
                            }
                        
                        }
                        else if(model_parts[i].render_slug=="temples") {
                            let founded=false
                            for(let k=0;k<cart_item.order_item_customizations.length;k++)
                            {
                                if(cart_item.order_item_customizations[k].model_part.id==model_parts[i].id)
                                {
                                    request+='&color_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].color.id
                                //params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                            
                                founded=true
                                }
                            }
    
                            if(founded==false) {
                                if(model_parts[i].material=="plastic")
                                {
                                    request+='&color_'+model_parts[i].render_slug+'=10'
                                //  params['color_'+model_parts[i].render_slug]=10
                                }
                                else {
                                    request+='&color_'+model_parts[i].render_slug+'=23'
                                //params['color_'+model_parts[i].render_slug]=23
                                }
                                
                            }
           
                        }
                        else {
                            let founded=false
                            for(let k=0;k<cart_item.order_item_customizations.length;k++)
                            {
                                if(cart_item.order_item_customizations[k].model_part.id==model_parts[i].id)
                                {
                                    request+='&color_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].color.id
                                // params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                            
                                founded=true
                                }
                            }
   
                            if(founded==false) {
                                if(model_parts[i].material=="plastic")
                                {
                                    request+='&color_'+model_parts[i].render_slug+'=10'
                                }
                                else {
                                    request+='&color_'+model_parts[i].render_slug+'=19'
                                }
                            
                            }
           
                        }
           
                        request+='&texture_'+model_parts[i].render_slug+'=4'
       
                    }
                            else if(model_parts[i].type=='texture_color') {
                                if(model_parts[i].render_slug=="frame")
                                {
                    
                                    // ON parcoure les order cart_item customizations pour voir si il y a le meme id que le model part courant
                                    let founded=false
                                    // Si oui on precise le param
                                    for(let k=0;k<cart_item.order_item_customizations.length;k++)
                                    {
                    
                                        if(cart_item.order_item_customizations[k].model_part.id==model_parts[i].id)
                                        {
                        
                                            founded=true
                                
                                            request+='&color_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].color.id

                                            if(cart_item.order_item_customizations[k].texture_style)
                                            {
                                                request+='&texture_style_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].texture_style.id
                                            }
                                            else 
                                            {
                                                request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                            }

                                            if(cart_item.order_item_customizations[k].texture)
                                            {
                                                request+='&texture_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].texture.id
                                            }
                                            else 
                                            {
                                                request+='&texture_'+model_parts[i].render_slug+'=4'
                                            }
                        
                        //params['texture_style_'+model_parts[i].render_slug]=
                        
                        //params['texture_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].texture ? cart_item.order_cart_item_customizations[k].texture.id : 4
                                        }
                        
                                    }
            
                                    if(founded==false) {
                                        if(model_parts[i].material=="plastic")
                                        {
                                            request+='&color_'+model_parts[i].render_slug+'=10'
                                        // params['color_'+model_parts[i].render_slug]=10
                                        }
                                        else {
                                            request+='&color_'+model_parts[i].render_slug+'=19'
                                        //params['color_'+model_parts[i].render_slug]=19
                                        }
                                        
                                        request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                        request+='&texture_'+model_parts[i].render_slug+'=4'

                                        // params['texture_style_'+model_parts[i].render_slug]= 0
                                    
                                        // params['texture_'+model_parts[i].render_slug]=4
                                    }
                    
                                }
                                else if(model_parts[i].render_slug=="temples") {
                                    let founded=false
                                    for(let k=0;k<cart_item.order_item_customizations.length;k++)
                                    {
                                        if(cart_item.order_item_customizations[k].model_part.id==model_parts[i].id)
                                        {
                                            request+='&color_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].color.id
                                        //params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id

                                        if(cart_item.order_item_customizations[k].texture)
                                        {
                                            request+='&texture_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].texture.id
                                        }
                                        else 
                                        {
                                            request+='&texture_'+model_parts[i].render_slug+'=4'
                                        }

                                        request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                        //params['texture_style_'+model_parts[i].render_slug]= 0
                                        founded=true
                                        }
                                    }
            
                                    if(founded==false) {
                                        if(model_parts[i].material=="plastic")
                                        {
                                            request+='&color_'+model_parts[i].render_slug+'=10'
                                        // params['color_'+model_parts[i].render_slug]=10
                                        }
                                        else {
                                            request+='&color_'+model_parts[i].render_slug+'=23'
                                        //params['color_'+model_parts[i].render_slug]=23
                                        }

                                        request+='&texture_'+model_parts[i].render_slug+'=4'
                            
                                        //params['texture_'+model_parts[i].render_slug]= 4

                                        request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                        //params['texture_style_'+model_parts[i].render_slug]= 0
                                    }
                    
                    
                                }
                            else {
                                let founded=false
                                for(let k=0;k<cart_item.order_item_customizations.length;k++)
                                {
                                    if(cart_item.order_item_customizations[k].model_part.id==model_parts[i].id)
                                    {

                                        request+='&color_'+model_parts[i].render_slug+'='+cart_item.order_item_customizations[k].color.id
                                    // params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                                
                                    founded=true
                                    }
                                }
                        
                                if(founded==false) {
                                    if(model_parts[i].material=="plastic")
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'=10'
                                    // params['color_'+model_parts[i].render_slug]=10
                                    }
                                    else {
                                        request+='&color_'+model_parts[i].render_slug+'=19'
                                    //params['color_'+model_parts[i].render_slug]=19
                                    }
                                
                                }
                                request+='&texture_'+model_parts[i].render_slug+'=4'
                    //params['texture_'+model_parts[i].render_slug]=4
                    
                            }
                    
                    
                        }
                        else if (model_parts[i].render_type=="lense")
                        {

                            if(cart_item.tint)
                        {
                            request+='&tint_'+model_parts[i].render_slug+'='+cart_item.tint.id
                        // params['color_'+model_parts[i].render_slug]=10
                        }
                        else {
                            request+='&tint_'+model_parts[i].render_slug+'=5'
                        //params['color_'+model_parts[i].render_slug]=19
                        }
                    
                    
                
                        }
                
                
                }
            }

            let datas = await APIRequest.list_renders('photos-layers-new', request)
                    //let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                    //let render_infos_datas=render_infos

            this.photoDatas=datas.scene1

            this.loading=false

           
            
        },
        calculatePhoto(layer_info) {

            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100
           

            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }
          
            
        
            return url
        
        },
        
                
    },
    data(){
      
        return{
            photoDatas:null,
            model_parts:null,
            photosLoaded:0,
            loading:true
        }
    },
    computed: {
    
    
    },
    async mounted(){
        await this.getModelParts()
        await this.getRenderInfos()
    }
}
</script>

<style scoped>

.loader-image {
    position:absolute;
    top:0;
    left:0;
    z-index:10;
}
.product-img-inner {
    position:absolute;
    top:0;
    left:0;
}
.product-img img {
    width:100%;
    height:auto;

}
.product-img {
    position:relative;

}
</style>