<template>
    <div class="menu-modal cover-modal header-footer-group show-modal" @click="closeModal" v-bind:class="{active:displayed}" data-modal-target-string=".menu-modal" >
	    <div class="menu-modal-inner modal-inner">
        <div class="menu-wrapper section-inner">
          <div class="menu-top">
          
            <button @click="closeMenu()" class="toggle close-nav-toggle fill-children-current-color" data-toggle-target=".menu-modal" data-toggle-body-class="showing-menu-modal" aria-expanded="false" data-set-focus=".menu-modal">
              <span class="toggle-text">Fermer le menu</span>
              <img src="../../../public/assets/images/cross.svg" width="55" />
              </button>

              <router-link to="/" @click="closeMenu()"><img width="548" height="93" src="../../../public/assets/images/2021/04/cropped-Groupe-463@2x.png" class="logo-burger" /></router-link>
              
              <br /><br /><br />
              <nav class="mobile-menu" aria-label="Mobile" role="navigation">
                <ul class="modal-menu reset-list-style">
                  <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-128 submenu-wrapper">
                    <div class="ancestor-wrapper submenu-title"> 
                      <router-link to="/collection">Collection Lunettes</router-link>
                    </div>
                    <div class="submenu">
                      <router-link @click="closeMenu()" :to="{ name: 'collection', params: { toggle: 'solaire' }}" class="link">Solaires<span class="line-menu"></span></router-link>
                      <router-link v-if="displayOptic" @click="closeMenu()" :to="{ name: 'collection', params: { toggle: 'optique' }}" class="link">Optiques<span class="line-menu"></span></router-link>
                      <router-link v-if="displayBluelight" @click="closeMenu()" :to="{ name: 'collection', params: { toggle: 'bluelight' }}" class="link">Anti-lumière bleue<span class="line-menu"></span></router-link>
                      <router-link v-if="displayGaming" @click="closeMenu()" :to="{ name: 'collection', params: { toggle: 'gaming' }}" class="link">Gaming<span class="line-menu"></span></router-link>
                      <router-link @click="closeMenu()" to="/collaborations" class="link">Collaborations</router-link>
                      <router-link @click="closeMenu()" :to="{ name: 'collection', params: { toggle: 'solaire' }}" class="link active">Toutes les lunettes<span class="line-menu"></span></router-link>
                    </div>
                  </li> -->
                  <!--
                  <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-42 current_page_item menu-item-50">
                    <div class="ancestor-wrapper">
                       <router-link to="/collaborations" @click="closeMenu()">Collaborations</router-link>
                    </div>
                  </li>

                  <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-42 current_page_item menu-item-50">
                    <div class="ancestor-wrapper">
                       <router-link to="/accessoires" @click="closeMenu()">Accessoires</router-link>
                    </div>
                  </li>
                  -->
                  <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291 submenu-wrapper">
                    <div class="ancestor-wrapper submenu-title"> 
                      <router-link to="/concept">Le concept</router-link>
                    </div>
                    <div class="submenu">
                      <router-link @click="closeMenu()" to="/concept" class="link">Le projet<span class="line-menu"></span></router-link>
                      <router-link @click="closeMenu()" to="/comment-ca-marche" class="link">Comment ça marche<span class="line-menu"></span></router-link>
                    </div>
                  </li> -->
                  <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link  v-if="language=='fr'" :to="{ name: 'collection', params: { toggle: 'optic' }}" @click="closeMenu()">Optique</router-link>
                      <router-link  v-else :to="{ name: 'collection', params: { toggle: 'optic' }}" @click="closeMenu()">Optic</router-link>
                    </div>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link v-if="language=='fr'"  to="/collections/solar" @click="closeMenu()">Solaire</router-link>
                      <router-link v-else   to="/collections/solar" @click="closeMenu()">Solar</router-link>
                    </div>
                  </li> -->
                  <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link v-if="language=='fr'"  to="/collections/solar" @click="closeMenu()">Personnaliser</router-link>
                      <router-link v-else  to="/collections/solar" @click="closeMenu()">Customize</router-link>
                    </div>
                  </li> -->

                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link v-if="language=='fr'"  to="/collection-beta" @click="closeMenu()">Collection BÊTA</router-link>
                      <router-link v-else   to="/collection-beta" @click="closeMenu()">BÊTA Collection</router-link>
                    </div>
                  </li>

                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link v-if="language=='fr'"  to="/comment-ca-marche" @click="closeMenu()">Comment ça marche</router-link>
                      <router-link v-else  to="/comment-ca-marche" @click="closeMenu()">How it works</router-link>
                    </div>
                  </li>

                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link v-if="language=='fr'"  to="/" @click="closeMenu()">Accueil</router-link>
                      <router-link v-else   to="/" @click="closeMenu()">Home</router-link>
                    </div>
                  </li>
                 
                  <!--
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-291">
                    <div class="ancestor-wrapper"> 
                      <router-link to="/nos-valeurs" @click="closeMenu()">Valeurs écologiques</router-link>
                    </div>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-42 current_page_item menu-item-50">
                    <div class="ancestor-wrapper">
                       <router-link to="/telechargement-app"  @click="closeMenu()">Téléchargement App</router-link>
                    </div>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-42 current_page_item menu-item-50">
                    <div class="ancestor-wrapper">
                       <router-link to="/comment-ca-marche"  @click="closeMenu()">Comment ça marche</router-link>
                    </div>
                  </li>
                  
                   <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-42 current_page_item menu-item-50">
                    <div class="ancestor-wrapper">
                        <router-link to="/mon-compte" @click="closeMenu()">Compte</router-link>
                    </div>
                  </li>
                  -->

                  
                </ul>
					</nav>
			  </div>

        <div class="buttons-burger" v-if='useAuth.isAuth==false'>
          <button  v-if="language=='fr'"><router-link to="/mon-compte" @click="closeMenu()">S'inscrire</router-link></button>
          <button  v-else><router-link to="/mon-compte" @click="closeMenu()">Subscribe</router-link></button>

          <button  v-if="language=='fr'" class="outline"><router-link to="/mon-compte" @click="closeMenu()">Se connecter</router-link></button>
          <button  v-else class="outline"><router-link to="/mon-compte" @click="closeMenu()">Log in</router-link></button>
        </div>
        <div class="buttons-burger" v-else>
          <button v-if="language=='fr'"><router-link to="/mon-compte" @click="closeMenu()">Mon compte</router-link></button>
          <button v-else><router-link to="/mon-compte" @click="closeMenu()">My account</router-link></button>
          
        </div>
       

        <div class="download-row" v-if="displayDownloadRow"><span class="cross" @click.prevent="displayDownloadRow=false;"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="10"></span>
            <a href="#" class="icon-dl"><img src="../../../public/assets/images/2021/05/download.png" /></a> <div class="text">Télécharger l’application IOS afin d’ajuster cette monture à votre visage gratuitement. Nous scannons votre visage avec notre technologie unique. <a href="#">Télécharger ici</a></div>
        </div>
        <div class="content">
          <p v-if="language=='fr'" >Newsletter :<br />inscrivez-vous afin de recevoir nos offres</p>
          <p v-else>Newsletter :<br />subscribe to get offers</p>            
                
<!-- START - We recommend to place the below code where you want the form in your website html  -->
<div class="sib-form" style="text-align: center; background-color: transparent;">
  <div id="sib-form-container" class="sib-form-container">
   
    
    <div id="sib-container" class="sib-container--large sib-container--vertical" style="text-align:center; background-color:transparent; max-width:540px; border-radius:3px; border-width:0px; border-color:#C0CCD9; border-style:solid;">
      <form @submit.prevent="subscribeNl" data-type="subscription" novalidate="true">
        <div style="padding: 8px 0;">
          <div class="sib-input sib-form-block">
            <div class="form__entry entry_block">
                <input class="input" v-model="email" type="email"  autocomplete="off" placeholder="Email" data-required="true" required="">
              <label class="entry__error entry__error--primary" style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;">
              </label>
            </div>
          </div>
        </div>
        <div>
          <div class="sib-form-block" style="text-align: left">
            <button @click="subscribeNl" class="sib-form-block__button sib-form-block__button-with-loader" form="sib-form" type="submit">
              <svg class="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"></svg>
            </button>
          </div>
        </div>

        <input type="text" name="email_address_check" value="" class="input--hidden">
        <input type="hidden" name="locale" value="fr">
      </form>
      <div v-if="error_nl==true" id="error-message"  style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:540px;">
      
      <span class="sib-form-message-panel__inner-text">
                        Nous n'avons pas pu confirmer votre inscription.
                    </span>
   
  </div>
 
  <div id="success-message" v-if="success_nl==true"  style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66;max-width:540px;">
    
      <span class="sib-form-message-panel__inner-text">
                        Votre inscription est confirmée.
                    </span>
    
  </div>
    </div>
  </div>
</div>
        </div>

        <br /><br />
       
       <div class="account_menu">
         <router-link  v-if="language=='fr'" to="/mon-compte" @click="closeMenu()"><img src="../../../public/assets/images/scan_icon.svg" width="16" /> Vos Scans</router-link>
         <router-link  v-else to="/mon-compte" @click="closeMenu()"><img src="../../../public/assets/images/scan_icon.svg" width="16" /> Your Scans</router-link>
         <router-link v-if="language=='fr'" to="/mon-compte" @click="closeMenu()"><img src="../../../public/assets/images/order_icon.svg" width="16" /> Commandes</router-link>
         <router-link v-else to="/mon-compte" @click="closeMenu()"><img src="../../../public/assets/images/order_icon.svg" width="16" /> Orders</router-link>
         <a  v-if="language=='fr'" @click="displayCart()"><img src="../../../public/assets/images/cart_icon.svg" width="16" /> Panier</a>
         <a  v-else @click="displayCart()"><img src="../../../public/assets/images/cart_icon.svg" width="16" /> Cart</a>
         <router-link to="/faq" v-if="language=='fr'" ><img src="../../../public/assets/images/question_icon.svg" width="16" /> Aide</router-link>
         <router-link to="/faq" v-else ><img src="../../../public/assets/images/question_icon.svg" width="16" /> Help</router-link>
         <router-link v-if="language=='fr'" to="/likes" @click="closeMenu()"><img src="../../../public/assets/images/favorite_icon.svg" width="16" /> Favoris & Sauvegardes</router-link>
         <router-link v-else to="/likes" @click="closeMenu()"><img src="../../../public/assets/images/favorite_icon.svg" width="16" /> Favorites & Customizations</router-link>
       </div>

       
       <br /><br /><br />

       <div class="social-menu">
        <div class="lang-select" :class="{opened:langMenuOpened}" @click="langMenuOpened=!langMenuOpened">
          <a  :class="{active:language=='fr'}" @click="selectLanguage('fr')">FR</a>
          <a  :class="{active:language=='en'}" @click="selectLanguage('en')">EN</a>
        </div>
         <a href="https://www.facebook.com/visages.eyewear"><img src="../../../public/assets/images/fb_icon.svg" height="15" /></a>
         <a href="https://www.linkedin.com/company/visages?trk=public_profile_topcard-current-company"><img src="../../../public/assets/images/linkedin.svg" height="15" /></a>
         <a href="https://www.instagram.com/visages.eyewear?igsh=azU4MXRlbjhoZ3l3"><img src="../../../public/assets/images/insta_icon.svg" height="15" /></a>
       </div>
       <img src="../../../public/assets/collectionglass/space.gif" class="bottom-space" />

		</div>

	</div>

</div>
</template>


<script>
import {Auth} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'
import { useAuthStore } from '@/store/authStore.js';
import { useSettingsStore } from '@/store/SettingsStore.js';


export default {
  components: {  },
  name: 'MobileMenu',
  props:['displayed',"language"],
  data() {
    return {
      useSettings:useSettingsStore(),
      useAuth: useAuthStore(),
      isAuth:false,
     user:null,
     langMenuOpened:false,
      username:'',
     password:'',
     name:'',
     surname:'',
     error_nl:false,
     success_nl:false,
     email:'',
     errorLogin:'',
    errorRegister:'',
    success:false,
    email_forgot:'',
    errorReset:'',
    displayDownloadRow:false,
    displayOptic:false,
    displayBluelight:false,
    displayGaming:false,
    }
  },

  async mounted(){
    
    
      if(this.useSettings.settingsLoaded==false) {
          //await this.useSettings.loadSettings()
      }
      
      if(this.useSettings.displayOptic==true) {
          this.displayOptic=true;
      }
      if(this.useSettings.displayBluelight==true) {
          this.displayBluelight=true;
      }
      if(this.useSettings.displayGaming==true) {
          this.displayGaming=true;
      }
   
    if(localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth) {
      this.isAuth=true
    }
  
    jQuery('.submenu-title').click(function(e){
      if(jQuery(this).parent().find('.submenu').is(':visible')){
        jQuery(this).parent().find('.submenu').slideUp()
      }
      else {
        jQuery(this).parent().find('.submenu').slideDown()
      }
    });
  },
  methods:{
    selectLanguage(lang){
      this.$emit('changeLanguageEv', lang)
    },
    async subscribeBrevo(email)
    {
      const SibApiV3Sdk = require('@getbrevo/brevo');
      let apiInstance = new SibApiV3Sdk.ContactsApi();

      apiInstance.setApiKey(SibApiV3Sdk.AccountApiApiKeys.apiKey, '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')

      let createContact = new SibApiV3Sdk.CreateContact();

      createContact.email = email;
      createContact.listIds = [5]

      let _this=this

      apiInstance.createContact(createContact).then(function(data) {
        _this.success_nl=true;
        _this.error_nl=false;
      }, function(error) {
        _this.error_nl=true;
        _this.success_nl=false;
        
      });
    },
    async subscribeNl()
    {
     

      await this.subscribeBrevo(this.email);
      
     
    },
   
    changeNewsletterEmail(value)
    {

       emitter.emit('changeNewsletterEmail', value);
    },
    closeModal($event)
    {
      
        
        if(jQuery($event.target).parents('.menu-modal-inner').length)
        {
           
        }
        else
        {
          emitter.emit('closePopupLogin')
            $event.preventDefault()
        }
        
    },
      closeMenu()
    {
        emitter.emit('closePopupLogin')
   
    },
    displayCart() {
      emitter.emit('display-cart')
      this.closeMenu()
    },
   
    
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-select a.active::after
{
  content:'>';
  position:absolute;
  right:10px;
  top:8px;
  transform: rotate(90deg);
}
.lang-select.opened a
{
  display:block;
}
.lang-select a.active 
{
  position:relative;
  display:block;
  border: 1px solid rgba(0, 0, 0, 1);
border-radius: 30px;
}
.lang-select a
{
  display:none;
  color:#000;
  font-size:12px;
  font-weight: 600;
  text-decoration: none;
  
padding:8px 30px 8px 10px
}
.lang-select
{
  display:inline-block;
  
}
.bottom-space {
  height:100px;
}
body .submenu a .line-menu
{
  background:gray;
  height:1px;
  position:absolute;
  bottom:0;
  left:0;
  width:0;
  transition:all 0.3s;
}
body .submenu a.active .line-menu {
  width:100%;
}
body .submenu a:hover .line-menu {
  width:100%;
}
body .submenu a {
  position:relative;
  font-size:17px !important;
}
.submenu
{
  display: none;
  padding-left:0px;
}
.submenu-title::after
{
  content:' ';
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  width:5px;
  height:9px;
  right:10px;
  background-size:100% 100%;
  background-image: url(../../../public/assets/images/rightarrow.svg);
}
.submenu-title
{
  position: relative;

}
.menu-modal-inner .content p
{
  font-size:12px;
}
.account_menu a img 
{
  display: inline-block;
      position: relative;
    top: 3px;
  margin-right: 10px;
}

.account_menu a
{
  color:#000;
  margin-top:10px;
  display:block;
  text-decoration: none;
  font-size: 16px;

}
.sib-form .entry__error
{
  padding-top:0;
  padding-bottom:0;
}
button.outline 
{
  background:#fff;
  color:#000;
  margin-left:10px;
}
.sib-form
{
  padding:0;
}
button.outline a
{
  color:#000;
}
button a
{
  color:#fff;
  text-decoration: none;
}
button
{
  font-weight: normal;
  display:block;
  margin-bottom:10px;
  text-transform: none;
  background:#000;
  color:#fff;
  border:1px solid black;
  border-radius:31px;
  height:35px;
  width:130px;
  padding:0px 0px;
  font-size:12px;
}
.menu-modal
{
  display:none;
}
.menu-modal.active
{
  display:flex;
}
.social-menu img
{
  display:inline-block;
  height:15px !important;

}
.social-menu a
{
  margin-left:20px;
  margin-right:20px;
}
.social-menu
{
  text-align:left;
  margin-bottom:60px;
}
.buttons-burger {
  display: flex;
  margin-top:30px;
  margin-bottom:30px;
}
.logo-burger 
{
  position:absolute;
  top:20px;
  width:127px;
  left:50%;
  transform: translateX(-50%);
}
</style>
