<template>
    <div id="forgot-popup" @click="closeModal($event)" >
			<div class="content">
				<span id="cross" @click="closeForgot()"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
        
        <div id="hello" v-if="success==false" >
          <span v-if="language=='fr'">Mot de passe oublié ?</span>
          <span v-else>Forgot your password?</span>
        </div>
				<p v-if="success==false">
          <span v-if="language=='fr'">Si vous avez besoin d’aide pour réinitialiser votre mot de passe, nous pouvons vous envoyer un lien de réinitialisation.
          </span>
          <span v-else>If you need help to reset your password, we can send you a reset link.
          </span>
        </p>
				<div class="login-form">
					<input v-if="success==false" type="email" v-model="email_forgot" id="email-forgot" placeholder="Email" />
					<button v-if="success==false" id="forgot-button" @click="sendResetPassword()">
            <span v-if="language=='fr'">Envoyer</span>
            <span v-else>Send</span>
          </button>
          <div v-if="success==false">{{errorReset}}</div>
          <div v-if="success">
            <span v-if="language=='fr'">
              Un email vient de vous être envoyé !
            </span>
            <span v-else>We just sent an email to you!</span>
          </div>
				</div>
			</div>
		</div>
</template>


<script>
import {Auth} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'

export default {
  components: {  },
  name: 'PopupForgotPassword',
  data() {
    return {
    email_forgot:'',
    errorReset:'',
    success:false
    }
  },
  props:["language"],
  async mounted(){
    
   
    
  },
  methods:{
    closeModal($event)
    {
         if (jQuery($event.target).parents('.content').length) {

        }
        else
        {
             emitter.emit('closePopupLogin')
        }
    },
    closeForgot()
    {
      emitter.emit('closePopupLogin')
    },
     async sendResetPassword(){
		
      let Parameters = {};
      Parameters.email = this.email_forgot;
      try {
          this.user=await Auth.send_reset_password(Parameters)
          console.log('response reset')
          console.log(this.user)
          if(this.user)
          {
            this.errorReset='';
          this.success=true
          }
          else
          {
            if(this.language=='fr')
            {
              this.errorReset='Email non trouvé, veuillez vérifier vos informations.';
            }
            else
            {
              this.errorReset='Email not found, please check your informations.';
            }
            
          }
          
      } catch (error) {
        if(this.language=='fr')
            {
              this.errorReset='Email non trouvé, veuillez vérifier vos informations.';
            }
            else
            {
              this.errorReset='Email not found, please check your informations.';
            }
      }  
    },

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
