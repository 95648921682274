<template>
  <footer id="site-footer" role="contentinfo" class="header-footer-group">
   
    <div class="top-footer">
   

<FooterNewsletter :language="language" :displayModalMenu="displayModalMenu"
                @changeNewsletterEmailEv="changeNewsletterEmail"
/>

<div class="line"></div>
        
      

        <FooterGetApp :language="language" />
    
      </div>
      <div class="footer-bottom">
        <div class="left">
          <div class="top">
           

            <FooterSocials />

            
            <FooterTopRight :language="language" />
          </div>

        

          <FooterMarquee />

        </div>
      
     
      <div class="right">
       

      <FooterDesktopRight 
      :language="language"
                          :languages="languages"
                          @selectLanguageEv="selectLanguage"
      />

     

<!-- 
      <FooterMobileRight 
                        :languages="languages"
      /> -->

      <div class="marquee-content contenumob">
              <FooterMarqueeMobileRight />
</div>
     
      <FooterRightBottom @selectLanguageEv="selectLanguage"  :language="language" />

      </div>
      

         


    </div>
   
   
  
    </footer>
</template>

<script>
import emitter from 'tiny-emitter/instance'
import {APIRequest} from '@simb2s/senseye-sdk'
import jQuery  from 'jquery';

// Import children
import FooterNewsletter from "../children/Footer/FooterNewsletterComponent.vue"
import FooterGetApp from "../children/Footer/FooterGetAppComponent.vue"
import FooterSocials from "../children/Footer/FooterSocialsComponent.vue"
import FooterTopRight from "../children/Footer/FooterTopRightComponent.vue"
import FooterMarquee from "../children/Footer/FooterMarqueeComponent.vue"
import FooterDesktopRight from "../children/Footer/FooterDesktopRightComponent.vue"
import FooterMobileRight from "../children/Footer/FooterMobileRightComponent.vue"
import FooterRightBottom from "../children/Footer/FooterRightBottomComponent.vue"
import FooterMarqueeMobileRight from "../children/Footer/FooterMarqueeMobileComponent.vue"


export default {
  name: 'Footer',
  components: {
    FooterNewsletter,
    FooterGetApp,
    FooterSocials,
    FooterTopRight,
    FooterMarquee,
    FooterDesktopRight,
    FooterMobileRight,
    FooterRightBottom,
    FooterMarqueeMobileRight
  },
  data() {
    return {
      glasses: null,
      rows: null,
      loaded: false,
      languages:null,
      currentYear:new Date().getFullYear()
    }
  },
  props:["displayModalMenu","language"],
  async mounted(){
      
      await this.getLanguages()
      window.REQUIRED_CODE_ERROR_MESSAGE = 'Veuillez choisir un code pays';
      window.LOCALE = 'fr';
      window.EMAIL_INVALID_MESSAGE = window.SMS_INVALID_MESSAGE = "Les informations que vous avez fournies ne sont pas valides. Veuillez vérifier le format du champ et réessayer.";

      window.REQUIRED_ERROR_MESSAGE = "Vous devez renseigner ce champ. ";

      window.GENERIC_INVALID_MESSAGE = "Les informations que vous avez fournies ne sont pas valides. Veuillez vérifier le format du champ et réessayer.";




      window.translation = {
        common: {
          selectedList: '{quantity} liste sélectionnée',
          selectedLists: '{quantity} listes sélectionnées'
        }
      }; 

      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://sibforms.com/forms/end-form/build/main.js')
      document.head.appendChild(recaptchaScript)

      //var AUTOHIDE = Boolean(0);
  },
  methods:{
    scrollToTop() {
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500); 
    },
    selectLanguage(id)
    {
      window.scrollTo(0,0)
      localStorage.senseye_language=id
      
      document.location.reload()
    },
    async getLanguages(){
      let languages=await APIRequest.list('languages')
      this.languages=languages[0]

    },
    changeNewsletterEmail(value)
    {

       emitter.emit('changeNewsletterEmail', value);
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.line{
  position: relative;
  top:-8.3rem;
  margin-bottom:-8.3rem;
  width: 1px;
  background: #707070;
}

#site-footer .cadre .content img
{
  width:80%;
}

#site-footer  .top-footer
{
  display:flex;
  border-bottom:1px solid #707070;

}

#site-footer .footer-bottom .left .top {
  display: flex;
  height:calc(100% - 135px);
}

#site-footer .footer-bottom .top-left {
  display:flex;
  flex-direction: column;
  width:60%;

}

#site-footer .footer-bottom .left {
  padding-left:0;
  padding-right:0;
}

#site-footer .footer-bottom .right {
  width:55%;
  padding-left:0;
  border-left:1px solid #707070;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
#site-footer .footer-bottom .right-bottom {
  border-top:1px solid #707070;
  padding-top:55px;
  padding-bottom:54px;
  padding-left:5%;
  width:100%;
}

#site-footer .footer-bottom .right-bottom a {
  margin-right:11%;
  cursor: pointer;
  word-break: keep-all;
}

#site-footer .footer-bottom {
  display: flex;
}
#site-footer .right-bottom {
  display: flex;
}
#site-footer .right-bottom a span {
  word-wrap: normal;
    word-break: keep-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
}
#site-footer .right-bottom a {
  display:inline-block;
  word-break: keep-all;
}
body #site-footer {
  padding-bottom: 0 !important;
}
#site-footer .footer-bottom .left .bottom {
  padding-right:0;
}
#site-footer 
{
  position: relative;
}
#site-footer .to-top{
  position:absolute;
  bottom:0;
  cursor: pointer;
  right:0;
  background:#000;
  padding:20px 30px;
  border-left:1px solid #707070;
  border-top:1px solid #707070;
  border-bottom:1px solid #707070;
}
#site-footer .to-top img {
  display: inline-block;
}
#site-footer .bottom {
  padding-left: 0 !important;
}

</style>
