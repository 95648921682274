import { createSSRApp } from 'vue'
import App from './App.vue'
import router from './router'
import './helpers/axios';
import '@simb2s/senseye-sdk';
import { createPinia } from 'pinia'
import Vue3TouchEvents from "vue3-touch-events";
import { createMetaManager } from 'vue-meta'


if(!localStorage.reset_caches_visages199)
{
    
    localStorage.removeItem('token_senseye_auth')
    localStorage.removeItem('refresh_token_senseye_auth')
    localStorage.removeItem('id_user_senseye_auth')
    localStorage.reset_caches_visages199=true
}


const app = createSSRApp(App).use(createPinia()).use(Vue3TouchEvents).use(createMetaManager())
app.use(router)

// Removed Vue Warn for development, think about turn it on before testing !! 
app.config.warnHandler = () => null;

app.mount('#app');

