import { defineStore } from 'pinia'
import { APIRequest } from "@simb2s/senseye-sdk";


export const useSettingsStore = defineStore('settings', {
  state: () => ({
    loadingShapes:true,
    loadingNoses:true,
    loadingGenders:true,
    loadingMaterials:true,
    displaySiliconNose:false,
    displayIntegratedNose:false,
    displayUnisex:true,
    displayMen:false,
    displayWomen:false,
    displayPantos:false,
    displayAviator:false,
    displayButterfly:false,
    displaySquare:false,
    displayRectangle:false,
    displayOval:false,
    displayRound:false, 
    displayMobileBottomBar: false, 
    displayOptic:false, 
    displayBluelight:true, 
   
    displayGaming:false, 
    displayPlastic:true,
    displayMetal:false,
    
    settingsLoaded:true ,
    // NOT USED
    displaySolar:true,
    displayCollab:true,
  }),
  getters: {

  },
  actions: {
    async loadSettings() {
    
      let request
      let glasses

      this.loadingMaterials=false
      this.loadingShapes=false
      this.loadingGenders=false
      this.loadingNoses=false
      
      request='?type=collection&shape=pantos'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayPantos=true
      }

      request='?type=collection&shape=round'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayRound=true
      }
      request='?type=collection&shape=oval'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayOval=true
      }
      request='?type=collection&shape=rectangle'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayRectangle=true
      }

      request='?type=collection&shape=square'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displaySquare=true
      }
      request='?type=collection&shape=butterfly'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayButterfly=true
      }
      request='?type=collection&shape=aviator'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayAviator=true
      }

      

      request='?type=collection&gender=men'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayMen=true
      }

      request='?type=collection&gender=women'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayWomen=true
      }

      request='?type=collection&gender=unisex'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayUnisex=true
      }

      

      request='?type=collection&nose=silicon'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displaySiliconNose=true
      }

      request='?type=collection&nose=integrated'
      glasses = await APIRequest.list_with_request('glasses',request);
      if(glasses[0].length>0)
      {
          this.displayIntegratedNose=true
      }
     
    },

  },
})