import { defineStore } from 'pinia'
import { Auth,APIRequest } from "@simb2s/senseye-sdk";

import parseJwt from "../utils/jwt/extractPayload";

// Import stores
import { useAccountStore } from './accountStore';
import { useCartStore } from './cartStore';
import { usePopupStore } from './popupStore';
import { useOrderItemStore } from './orderItemStore';
import sendinblue  from 'sendinblue';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuth: localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth ? true : false,
    user: null,
    userID: localStorage.id_user_senseye_auth,
    username: localStorage.username_user_senseye_auth,
    authLoaded: false,
    userLoginCheckout:false,
    userLogout:false,
    userLogin:false,
    closeLogin:false,
    reloadUserContent:false,
    reloadUserContentCheckout:false,
    userRegisterObject:null,
    userRegisterCheckoutObject:null,
    userRegister:false,
    userRegisterCheckout:false,
    useAccount: useAccountStore(),
    useCart: useCartStore(),
    useOrderItem: useOrderItemStore(),
    usePopup: usePopupStore(),
  }),
  actions: {
    async getUser(username = this.username) {
      try {
        this.authLoaded = false;
        const user = await Auth.get_user(username);
        this.user = user;
        
        return user;
      } catch (err) {
        console.log(err);
      } finally {
        this.authLoaded = true;
      }
    },
    async loginUser(username, password, checkout = false) {
      
      console.log('login user store')
      console.log(username)
      console.log(password)
      console.log('checkout:'+checkout)
      sendinblue.identify(this.username);
      this.authLoaded = false;
      let Parameters = {};
      Parameters.username = username;
      Parameters.password = password;
      try {
        let user = await Auth.login(Parameters)
        if (!user) {

            console.log('login failed store')
            console.log(user)


          this.userLoginCheckout=null
          this.userLogin=null
          
    
          
          return null
        }

        

      
        localStorage.token_senseye_auth = user.token;
        localStorage.refresh_token_senseye_auth = user.refresh_token;
        
        //this.closeLogin=!this.closeLogin
        
        
        let user2 = await parseJwt(user.token);
        localStorage.username_user_senseye_auth = user2.username;
        console.log('on get le user')
        console.log(user2.username)
        const user_data = await this.getUser(user2.username);

        
        this.username=user2.username
        this.user = user_data;
        this.isAuth=true
        localStorage.id_user_senseye_auth = user_data.id;
        this.userID = user_data.id;

        
        if(checkout==true)
        {

         
          

          this.userLoginCheckout=user_data
          this.reloadUserContentCheckout=!this.reloadUserContentCheckout


        }
        else 
        {
          this.userLogin=user_data
          
          this.reloadUserContent=!this.reloadUserContent
        
        }
          
        
         
        this.authLoaded = true;
        return user
      } catch (err) {
        console.log(err);

          this.userLoginCheckout=null
          this.userLogin=null
          
        
        return null
      } finally {
        
        this.authLoaded = true;
        
        await this.useCart.assignCurrentCartAfterLogin(this.user);
        
       
        //return user
      }
    },
    async registerUser(userObj) {
      console.log('register stooore')
      this.authLoaded = false;
      let Parameters = {};
      Parameters.username = userObj.email;
      Parameters.password = userObj.password;
      Parameters.name = userObj.name;
      Parameters.phone = userObj.phone;
      Parameters.surname = userObj.surname;
      Parameters.email = userObj.email;
      try {
        let user = await Auth.register(Parameters)

        console.log('resgiettttttttttttttttttttter')
        console.log(user)
        
        if (user) {
        

          this.userRegister=!this.userRegister
          this.userRegisterObject=user
          this.userRegisterCheckout=!this.userRegisterCheckout
          this.userRegisterCheckoutObject=user
          
          return user
        }
        else {
      
          this.userRegister=!this.userRegister
          this.userRegisterObject=null
          this.userRegisterCheckout=!this.userRegisterCheckout
          this.userRegisterCheckoutObject=null
        
          return null
        }
      } catch (err) {
        console.log(err)
        this.userRegister=!this.userRegister
        this.userRegisterObject=null
        this.userRegisterCheckout=!this.userRegisterCheckout
        this.userRegisterCheckoutObject=null

        return null
      } finally {
        this.authLoaded = true;
        await this.useCart.assignCurrentCartAfterLogin(this.user);

      }
    },
    async refreshToken(){
          let Parameters={};
          Parameters.refresh_token = localStorage.refresh_token_senseye_auth;
          let user = await Auth.refresh_token(Parameters)
          localStorage.token_senseye_auth = user.token
          localStorage.refresh_token_senseye_auth = user.refresh_token
      },
    async handleLogout() {
      try {

       
        this.authLoaded = false;

        // if cart, update it by removing user

        await Auth.logout()
        this.user = null;
        this.isAuth=false

        await this.useCart.unAssignCurrentCartAfterLogout()
        this.resetStore();

       
        this.userLogout=!this.userLogout

        
        


        document.location.reload();
       // return this.isAuth;
      } catch (err) {
        console.log(err);
      } finally {
        this.authLoaded = true;
      }
    },
    async handleLogoutCheckout() {
      try {

       
        this.authLoaded = false;

        // if cart, update it by removing user

        await Auth.logout()
        this.user = null;
        this.isAuth=false

        await this.useCart.unAssignCurrentCartAfterLogout()
        this.resetStore();

       
        this.userLogout=!this.userLogout

        
        


        //document.location.reload();
       // return this.isAuth;
      } catch (err) {
        console.log(err);
      } finally {
        this.authLoaded = true;
      }
    },
    resetStore(){
      this.isAuth = false;
      this.user = null;
      this.userID = "";
      this.username = "";
      this.authLoaded = false;
      this.resetAllStores();
    },
    resetAllStores(){
      const storesArray = [ this.useAccount ,this.usePopup ];
      storesArray.forEach(store => store.resetStore());
    }
  },
})