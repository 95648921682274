<template>
    <!-- <div v-if="step == 2 && currentOption == 'account'"> -->
    <div>
        <h3>Votre ordonnance</h3><br /><br />
        Choisissez une ordonnance existante :<br /><br />
        <div class="item">
            <template v-for="prescription in prescriptions" :key="prescription.id">
                <span @click="choosePrescription(prescription.id)" class="name">{{ prescription.name }}</span> <br>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "OptionAccount",
    props: ["prescriptions"],
    methods:{
        choosePrescription(id)
        {
            this.$emit('choosePrescriptionEv', id)
        }
    },
    data(){
        return{

        }
    }

}
</script>

<style scoped>
.item .name {
    cursor: pointer;
  font-weight: 700;
  display: block;
}
</style>