export default {
    items: [
        {
            id: "fb",
            link: "https://www.facebook.com/visages.eyewear",
        },
        {
            id: "linkedin",
            link: "https://www.linkedin.com/company/visages?trk=public_profile_topcard-current-company",
        },
        {
            id: "insta",
            link: "https://www.instagram.com/visages.eyewear?igsh=azU4MXRlbjhoZ3l3",
        },
        {
            id: "",
            link: "",
        }
    ]
}