const sizeArray = [
    {
        size: 's',
        title: 'small'
    },
    {
        size: 'm',
        title: 'medium'
    },
    {
        size: 'l',
        title: 'large'
    },
    {
        size: 'xl',
        title: 'extra large'
    },
]

const sizeSentenceFactory = (size) => {

const sizeSelected = [...sizeArray].filter(array => array.size === size)[0];
const sizeNotSelected = [...sizeArray].filter(array => array.size != size);

const notSelectedArray = [...sizeNotSelected].map(s => s.title );


    return `Cette monture est de taille ‘${sizeSelected.title.toUpperCase()}’. Elle est également disponible en ${notSelectedArray[0]} fit  ${notSelectedArray[1]} fit,  ${notSelectedArray[2]} fit et <span class="bold">sur-mesure</span>.
    <br />Pour obtenir un sur-mesure, ou pour connaître la taille idéale,  <span class="bold">télécharger l’application mobile</span>.`
}

module.exports = sizeSentenceFactory;