import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
    state: () => ({
        isAuth: localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth ? true : false,

        // HOMETOP COMPONENT

        popupMobDisplayed: false,

        // CART.VUE

        popupCart: false,

         // CARTITEM COMPONENT COLLABSECTION COMPONENT CART.VUE COLLECTIONGLASS VIEW

        displayOnMesurePopup: false,

        // HOMEPRODUCE COMPONENT

        displayVideoPopup: false,

        // CARTITEM COMPONENT CART.VUE

        displayPopupBg: false,

        // CARTITEM COMPONENT COLLABSECTION COMPONENT CART.VUE ACCESSOIREDETAIL COMPONENT COLLECTIONGLASS VIEW

        displayAppPopup: false,

        // ACCOUNTCONTENT COMPONENT POPUPSHARESCAN COMPONENT ACCOUNT VIEW
        displayPopupShareScan: false,

        // ACCOUNT VIEW
        displayScanPopup: false,

        // REFUND FAQ COMPONENT + FAQ VIEW
        displayDeliveryPopup: false,

        // APP.VUE
        displayHowItWorkPopup: false,
        displayPrescriptionPopup: false,
        displayReviewPopup: false,
        displayForgotPassword: false,
        displayLogin: false,
        displayNoScan: false,
        displayModalMenu: false,
        displayMobileBottomBar: false,
      }),
    actions: {
        togglePopupMob (bool) {
            this.popupMobDisplayed = bool;
        },
        togglePopupCart (bool) {
            this.popupCart = bool;
            // document.location.reload()
            
        },
        toggleHowItWorkPopup (bool) {
            this.displayHowItWorkPopup = bool;
        },
        toggleOnMesurePopup (bool) {
            this.displayOnMesurePopup = bool;
        },
        togglePrescriptionPopup (bool) {
            this.displayPrescriptionPopup = bool;
        },
        toggleReviewPopup (bool) {
            this.displayReviewPopup = bool;
        },
        toggleVideoPopup (bool) {
            this.displayVideoPopup = bool;
        },
        togglePopupBg (bool) {
            this.displayPopupBg = bool;
        },
        toggleAppPopup (bool) {
            this.displayAppPopup = bool;
        },
        togglePopupShareScan (bool) {
            this.displayPopupShareScan = bool;
        },
        toggleScanPopup (bool) {
            this.displayScanPopup = bool;
        },
        toggleDeliveryPopup (bool) {
            this.displayDeliveryPopup = bool;
        },
        toggleForgotPassword (bool) {
            this.displayForgotPassword = bool;
        },
        toggleLogin (bool) {
            this.displayLogin = bool;
        },
        toggleNoScan (bool) {
            this.displayNoScan = bool;
        },
        toggleModalMenu (bool) {
            this.displayModalMenu = bool;
        },
        toggleMobileBottomBar (bool) {
            this.displayMobileBottomBar = bool;
        },
        resetStore(){
            this.isAuth = false,            
            this.popupMobDisplayed = false,
            this.popupCart = false,
            this.displayOnMesurePopup = false,
            this.displayVideoPopup = false,
            this.displayPopupBg = false,
            this.displayAppPopup = false,
            this.displayPopupShareScan = false,
            this.displayScanPopup = false,
            this.displayDeliveryPopup = false,
            this.displayHowItWorkPopup = false,
            this.displayPrescriptionPopup = false,
            this.displayReviewPopup = false,
            this.displayForgotPassword = false,
            this.displayLogin = false,
            this.displayNoScan = false,
            this.displayModalMenu = false,
            this.displayMobileBottomBar = false
        }
    }
})