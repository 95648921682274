<template>
        <div class="right-bottom">
            <!-- <div class="to-top contenumob" @click="scrollToTop()">
          <img src="../../../../public/assets/images/top.svg" width="50" />

        </div> -->

        <template v-for="(i,index) in items" :key="index">
            <a @click="$router.push({ name: i.link })" > <span v-if="language=='fr'">{{ i.txt }}</span>
                      <span v-else>{{ i.txt_en }}</span><span class="line-footer"></span></a> 

                      
                      
        </template>
        <a> 
            <span v-if="language=='fr'">
                <span class="lang-link active" @click="selectLanguage('fr')">FR</span> / <span  class="lang-link"  @click="selectLanguage('en')">EN</span>
            </span>
            <span v-else>
                <span class="lang-link"  @click="selectLanguage('fr')">FR</span> / <span class="lang-link active"  @click="selectLanguage('en')">EN</span>
            </span>
        </a> 

        <!-- <a @click="$router.push({ name: 'confidentiality' })" href="#">POLITIQUE DE CONFIDENTIALITÉ</a> 
              <a @click="$router.push({ name: 'delivery' })" href="#">LIVRAISON + SUIVI</a> 
              <a @click="$router.push({ name: 'cgv' })" href="#" >CGV</a> 
             <a @click="$router.push({ name: 'cgv' })" href="#" >CGU</a>  -->
         
      </div>
</template>

<script>
import menuData from "../../../data/CGV/menu_data"
import jQuery  from 'jquery';

export default {
    name: "FooterRightBottom",
    props:['language'],
    data(){
        const {items} = menuData
        return{
            items
        }
    },
    methods:{
        selectLanguage(language){
            this.$emit('selectLanguageEv',language)
        },
        scrollToTop() {
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500); 
    },
    }
}
</script>

<style scoped>
    .lang-link
    {
        opacity:0.5;
        position: relative;
    }
   
    .lang-link.active
    {
        opacity:1;
      
    }
</style>