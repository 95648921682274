<template>
        <!-- <div class="marquee-content contenumob"> -->
            <template v-for="(l,index) in loop" :key="index">
                    <div class="marqueetext" v-for="(j,index) in loop" :key="index">
                        <template v-for="(j,index) in loop" :key="index">
                            © VISAGES 2024 
                        </template>
                    </div>
                </template>
            <!-- </div> -->
</template>

<script>
export default {
    name: "FooterMarqueeMob",
    data(){
        return{
            loop: 9
        }
    }
}
</script>

<style scoped>
.marquee-content {
  display:flex;
  overflow: hidden;
}
.marqueetext {
  white-space: nowrap;
  font-size:90px;
    animation: marqueelike 15s linear infinite;
    color:rgba(0, 0, 255, 1);
}
#site-footer .footer-bottom  .bottom {
  margin-top:0;
}
</style>