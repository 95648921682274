export default {
    prismPos: [
        {
        value: "top",
        angle: "90º",
        desc: "(Supérieur)"
    }
    ,
        {
        value: "bottom",
        angle: "270º",
        desc: "(Inférieur)"
    }
    ,
        {
        value: "in",
        angle: "0º",
        desc: "(Nasal)"
    }
    ,
        {
        value: "out",
        angle: "180º",
        desc: "(Temporal)"
    }
    ,
    ]
}