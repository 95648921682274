<template>
    <div class="product carousel-cell">
        <div class="soon-message">Bientôt disponible</div>
        <div class="like">
            <img src="../../../public/assets/images/heart.svg" width="26" />
        </div>
        <img src="../../../public/assets/home/blur.jpg" class="product-image" />
    </div>
</template>

<script>
export default {
    name: "AvailableSoon"
}
</script>

<style scoped>
.colors-count
{
    font-size:13px;
}

.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF00;
border-radius: 8px;
padding:5px 10px 5px 25px;
}

</style>