<template>
    <div id="popup-login" @click="closeModal($event)" class="popup-login">
			<div class="content">
				<span id="cross" @click="closePopup()"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
				
          <content-loader v-if="loaded==false" viewBox="0 0 520 230" primaryColor="#f3f3f3" secondaryColor="#cccccc">

            <rect x="0" y="0" rx="0" ry="0" width="230" height="230" />
            <rect x="320" y="20" rx="5" ry="5" width="150" height="10" />
            <rect x="320" y="60" rx="5" ry="5" width="150" height="10" />
            <rect x="320" y="100" rx="5" ry="5" width="150" height="10" />
            <rect x="320" y="140" rx="5" ry="5" width="150" height="10" />
            <rect x="320" y="180" rx="5" ry="5" width="150" height="10" />

        </content-loader> 
        
        <div class="left" v-if="loaded">
          <p ><span class="underline">qrCode</span></p>
        
        </div>
        <div class="right" v-if="loaded">
         <br /><br /><br /><br />
         
				 <p>
           <b>{{user}}</b>, vous n’avez pas encore effectué de scan de votre visage. Veuillez télécharger l’application mobile Senseye pour obtenir un sur-mesure
           </p>
         <br />
			<img src="../../../public/assets/images/download.svg" width="150" />   
      <br />
      <div class="legend">
      *uniquement disponible sur les appareils IOS dernières genérétion en possession de Face ID.
      </div>
      <br />
      <div class="device-list">
        <div class="display-list" @click="displayDevices()">
          Voir la liste des appareils compatibles
        </div>
        <div class="list" v-if="displayList">
          <div class="device">iPhone X</div>
          <div class="device">iPhone XR</div>
          <div class="device">iPhone XS</div>
          <div class="device">iPhone 11</div>
          <div class="device">iPhone 12</div>
          <div class="device">iPhone 13</div>
          <div class="device">iPad Pro 
          <span class="small">12,9 pouces (4e)</span>
          </div>
          <div class="device">iPad Pro 
<span class="small">12,9 pouces (3e)</span></div>

          <div class="device">iPad Pro 
<span class="small">11 pouces (2e)</span></div>
          <div class="device">iPad Pro 
<span class="small">11 pouces</span></div>
         
        </div>
      </div>
      <br /><br />
        </div>
       
				
			</div>
		</div>
</template>


<script>
import {Auth} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'
import { ContentLoader } from 'vue-content-loader';

export default {
  components: { ContentLoader },
  name: 'PopupNoScan',
  data() {
    return {
     user:'',
      displayList:false,
      loaded:false
    }
  },
  async mounted(){
    let user=this.parseJwt(localStorage.token_senseye_auth);
    let user_data=await Auth.get_user(user.username)
    this.user=user_data.name
    this.loaded=true
  },
  methods:{
   
     displayDevices()
     {
       if(this.displayList==false)
       {
         this.displayList=true
       }
       else
       {
         this.displayList=false
       }
     }
     ,
      closePopup()
    {
      emitter.emit('closePopupNoScan')
    },
    closeModal($event)
    {
        if (jQuery($event.target).parents('.content').length) {

        }
        else
        {
           
        }
    },
    
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
   
    
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
#cross
{
  position: absolute;
   
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
.device-list .display-list
{
  position: relative;
  cursor: pointer;
}
.device-list .device
{
  font-size:11px;
  background: rgba(255, 255, 255, 0.7);
  padding:5px;
  display:inline-block;
  margin-right:10px;
  margin-top:10px;
opacity: 1;
backdrop-filter: blur(24px);
-webkit-backdrop-filter: blur(24px);
}
.device-list .display-list::after
{
  background-image:url(../../../public/assets/images/arrow.svg);
  background-repeat: no-repeat;
  background-size:10px auto;
  background-position: center center;
  content:' ';
  position:absolute;
  right:0px;
  top:-5px;
  width: 28px;
height: 28px;
border-radius:50%;
background-color: #fff;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.device-list
{
  background: rgba(0, 0, 0, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  backdrop-filter: blur(30px);
  font-size:13px;
  padding:10px;
  -webkit-backdrop-filter: blur(30px);
}
#popup-login .content .right a.forgot
{
font-size:8px;
color:#000;
position:absolute;
right:10px;
top:50%;
transform:translateY(-50%);
text-decoration: none;
    border-bottom: 1px solid black;
cursor: pointer;
}
#popup-login .content
{
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
#popup-login .content .left p
{
  font-size:20px;
  padding-left:5%;
  padding-right:5%;
  position:relative;
  top:50%;
  transform:translateY(-50%);
}
#popup-login .content .left p .underline
{
  border-bottom:1px solid black;
}
#popup-login .content .left
{
	position:absolute;
	left:0;
	top:0;
	height:100%;
	background: transparent linear-gradient(90deg, rgba(196, 217, 253, 0.8) 0%, rgba(219, 209, 251, 0.8) 100%) 0% 0% no-repeat padding-box;
	display:inline-block;
	vertical-align: top;
	width:35%;
	float:left;
}
#popup-login .content .right p
{
  text-align: left;
  font-size:20px;
}
#popup-login .content .right
{
  padding-left:5%;
  padding-right:5%;
}
.legend
{
  font-size:13px;
}

</style>
