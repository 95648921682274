<template>
  
  <div class="megamenu contenudesk">
    <div class="left">
      <div class="strong">
        LE CONCEPT
      </div>
      <router-link @click="hideMegaMenu()" to="/concept" class="link">Le projet<span class="line-megamenu"></span></router-link>
      <router-link @click="hideMegaMenu()" to="/comment-ca-marche" class="link">Comment ça marche<span class="line-megamenu"></span></router-link>
  
    </div>
    <div class="right">
      <div class="right-img"></div>
      
    </div>
  </div>
    
</template>

<script>

import emitter from 'tiny-emitter/instance'

export default {
  name: 'MegaMenuConcept',
  data() {
    return {
      displaySearch:false,
    }
  },
  watch:{
    
  },
  async mounted(){
    
  },
  methods:{
    hideMegaMenu() {
      emitter.emit('hide-megamenus',null)
    }
  },

}
</script>
<style scoped>
.megamenu .link
{
  font-size:18px;
  text-decoration: none;
  margin-top:15px;
}
.megamenu .strong
{
  font-size:28px;
  margin-bottom: 20px;
  font-weight:500;
}
.megamenu
{
  padding:40px;
  width:100%;
  position:absolute;
  left:0;
  top:75px;
  z-index:9999999;
  display: flex;
  flex-direction: row;
  background:#fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  height:500px;
  animation: animmenu 0.3s linear 0s;
  overflow: hidden;
}
@keyframes animmenu {
  0% {
    height:0;
  }
  100% {
    height:500px;
  }
}
.megamenu .right
{
    width:50%;
    position: relative;
}
.megamenu .left
{
  width:50%;
}

.right-img {
  background-image:url(../../../public/assets/images/mm.jpg);
  background-size:cover;
  position: absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-position: center center;
}

</style>