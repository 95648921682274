import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(
   config => {
       config.headers['Authorization'] = localStorage.token_senseye_auth ? 'Bearer '+localStorage.token_senseye_auth : null;
       config.headers['Content-Type'] = 'application/json'; 
       return config;
   },
   error => {''
       Promise.reject(error)
});
