<template>
    <div class="right-top">

        <template v-for="(m, index) in menu" :key="index">
            <template v-if="m.length > 1">
                <div class="col" v-for="(item, index) in m" :key="index">
                    <div class="footer-section">
                        <span v-if="language=='fr'">{{ item.name }}</span>
                            <span v-else>{{ item.name_en }}</span>
                           </div>
                    <template v-for="i in item.array" :key="i.link">
                        <a v-if="i.link != null" @click="$router.push({ name: i.link, params:i.params })">
                            <span v-if="language=='fr'">{{ i.txt }}</span>
                            <span v-else>{{ i.txt_en }}</span>
                            <span class="line-footer"></span></a>
                        <a v-else href="#"><span v-if="language=='fr'">{{ i.txt }}</span>
                            <span v-else>{{ i.txt_en }}</span><span class="line-footer"></span></a>
                        <template v-if="i.link === 'accessories'">
                            <br>
                        </template>
                    </template>
                </div>
                <br>
            </template>
            <div v-else class="col">
                <div class="footer-section">{{ m.name }}</div>
                <template v-for="i in m.array" :key="i.link">
                    <a v-if="i.link != null" @click="$router.push({ name: i.link, params:i.params })" >{{ i.txt }}<span class="line-footer"></span></a>
                    <br>
                    <br>
                    <div v-for="language in languages" :key="language.id">
                        <a  v-if="language.default_language == true" @click="selectLanguage('none')">{{
                            language.name
                        }}<span class="line-footer"></span></a>
                        <a  v-else @click="selectLanguage(language.id)">{{ language.name }}<span class="line-footer"></span></a>
                    </div>
                </template>
            </div>
        </template>

        <!-- <div class="col">
            <div class="footer-section">COLLECTION</div>
            <a @click="$router.push({ name: 'collection' })" href="#">SOLAIRES</a>
            <a @click="$router.push({ name: 'collection' })" href="#">OPTIQUES</a>
            <a @click="$router.push({ name: 'collection' })" href="#">ANTI LUMIÈRE BLEUE</a>
            <a @click="$router.push({ name: 'accessories' })" href="#">ACCESSOIRES</a>

            <br />
            <div class="footer-section">+ COLLABORATIONS</div>
            <a @click="$router.push({ name: 'collaboration' })" href="#">SENSEYE X REVEUR LUCIDE</a>


        </div>

        <div class="col">

            <div class="footer-section">LE CONCEPT</div>

            <a @click="$router.push({ name: 'howdoesitwork' })" href="#">COMMENT ÇA MARCHE</a>
            <a @click="$router.push({ name: 'concept' })" href="#">LE CONCEPT SENSEYE</a>
            <a @click="$router.push({ name: 'our-values' })" href="#">NOS VALEURS </a>
            <a @click="$router.push({ name: 'mutual-refund' })" href="#">REMBOURSEMENT MUTUELLE</a>
            <a href="#">PRESSE</a>


            <div class="footer-section">APPLICATION MOBILE</div>
            <a @click="$router.push({ name: 'download' })" href="#">TELECHARGEMENT APP IOS</a>


        </div>
        <div class="col">
            <div class="footer-section">APPLICATION MOBILE</div>
            <a @click="$router.push({ name: 'download' })" href="#">TELECHARGEMENT APP IOS</a>

            <br /><br />
            <div v-for="language in languages" :key="language.id">
                <a href="#" v-if="language.default_language == true" @click="selectLanguage('none')">{{ language.name }}</a>
                <a href="#" v-else @click="selectLanguage(language.id)">{{ language.name }}</a>
            </div>

        </div> -->
        <div class="to-top contenudesk"  @click="scrollToTop()">
      <img src="../../../../public/assets/images/top.svg" width="50" />

    </div>
    </div>
</template>

<script>
import footerMenu from "../../../data/footer/footer_menu"
import jQuery  from 'jquery';

export default {
    name: "FooterDesktopRight",
    props: ["languages", "language"],
    methods: {
        selectLanguage(language){
            this.$emit('selectLanguageEv',language)
        },
        scrollToTop() {
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500); 
    },
    },
    data() {
        const { menu } = footerMenu
        return {
            menu
        }
    }
    
}
</script>

<style>
#site-footer .footer-bottom .right .right-top {
    padding-left: 5%;
    display: flex;
    position:relative;
    height:100%;
    padding-bottom: 50px;
}

#site-footer .footer-bottom .right .col {
    width: 33%;
}
</style>