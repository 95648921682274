<template>
            <div class="bottom">
            <div class="marquee-content">
                <template v-for="(l,index) in loop" :key="index">
                    <div class="marqueetext">
                        <template v-for="(j,index) in loop" :key="index">
                            © VISAGES 2024 
                        </template>
                    </div>
                </template>
            </div>
          </div>
</template>

<script>
export default {
    name: "FooterMarquee",
    data(){
        return{
            loop: 5
        }
    }

}
</script>

<style scoped>

.marquee-content {
  display:flex;
  overflow: hidden;
}
.marqueetext {
  white-space: nowrap;
  line-height:120px;
  font-size:120px;
    animation: marqueelike 15s linear infinite;
   color:rgba(0, 0, 255, 1);
}
#site-footer .footer-bottom  .bottom {
  margin-top:0;
}

</style>