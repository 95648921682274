<template>
        <div class="right-top contenumob">
        <div class="col-line">
          
          <div class="col-line-left">
            <div class="col">
                <div class="footer-section">{{ concept.name }}</div>
                <template v-for="(item,index) in concept.array" :key="index">
                    <a @click="$router.push({ name: item.link , params:i.params})" >{{ item.txt }}</a>
                </template>
            <!-- </div>
            <div class="col"> -->
                <div class="footer-section">{{ application.name }}</div>
                <template v-for="(item,index) in application.array" :key="index">
                    <a @click="$router.push({ name: item.link , params:i.params})" >{{ item.txt }}</a>
                </template>
                <br><br>
                <div v-for="language in languages" :key="language.id">
                <a v-if="language.default_language==true" @click="selectLanguage('none')">{{language.name}}</a>
                <a  v-else @click="selectLanguage(language.id)">{{language.name}}</a>
              </div>
            </div>

        </div> 
            <div class="col-line-right">
                <div class="footer-section">{{ collection.name }}</div>
                <template v-for="(item,index) in collection.array" :key="index">
                    <a @click="$router.push({ name: item.link , params:i.params})" href="#">{{ item.txt }}</a>
                </template>
                <div class="footer-section">{{ collaboration.name }}</div>
                <template v-for="(item,index) in collaboration.array" :key="index">
                    <a @click="$router.push({ name: item.link, params:i.params })" href="#">{{ item.txt }}</a>
                </template>
            </div>

              <!-- <div class="footer-section">LE CONCEPT</div> 

                <a @click="$router.push({ name: 'howdoesitwork' })" href="#">COMMENT ÇA MARCHE</a>
                <a @click="$router.push({ name: 'concept' })" href="#">LE CONCEPT SENSEYE</a>
                <a @click="$router.push({ name: 'our-values' })" href="#">NOS VALEURS </a>
                <a @click="$router.push({ name: 'mutual-refund' })" href="#">REMBOURSEMENT MUTUELLE</a>
                <a href="#">PRESSE</a>


                
                
                
            </div>
            <div class="col">
              <div class="footer-section">APPLICATION MOBILE</div>
              <a @click="$router.push({ name: 'download' })" href="#">TELECHARGEMENT APP IOS</a>

              <br /><br />
              <div v-for="language in languages" :key="language.id">
                <a href="#" v-if="language.default_language==true" @click="selectLanguage('none')">{{language.name}}</a>
                <a href="#" v-else @click="selectLanguage(language.id)">{{language.name}}</a>
              </div>

            </div>
          

          </div>
          <div class="col-line-right">
            <div class="col">
              <div class="footer-section">COLLECTION</div>
              <a @click="$router.push({ name: 'collection' })" href="#">SOLAIRES</a>
              <a @click="$router.push({ name: 'collection' })" href="#">OPTIQUES</a>
              <a @click="$router.push({ name: 'collection' })" href="#">ANTI LUMIÈRE BLEUE</a>
              <a @click="$router.push({ name: 'accessories' })" href="#">ACCESSOIRES</a>

              <br />
              <div class="footer-section">+ COLLABORATIONS</div>
              <a @click="$router.push({ name: 'collaboration' })" href="#">SENSEYE X REVEUR LUCIDE</a>


            </div> -->
        

        </div> 

      
       
      </div>
</template>

<script>
import footerMenu from "../../../data/footer/footer_menu"

export default {
    name: "FooterMobileRight",
    props: [],
    methods: {},
    data(){
        const {menuMob} = footerMenu;
        const {collection, collaboration, concept, application} = menuMob;
        return {
            collection,
            collaboration,
            concept,
            application
        }
    }
}
</script>

<style>

#site-footer .footer-bottom .right .right-top.contenumob {
  display:none;
}

.marquee-content.contenumob{
  display:none;
}

.col-line-right{
    padding-left: 17px;
}

</style>