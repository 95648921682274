<template>
  
  <div class="megamenu contenudesk">
    <div class="left">
      <div>
        <div class="strong">
          TÉLÉCHARGEMENT VISAGES
        </div>
        <a href="#" class="link">Télécharger l’application IOS</a><br />
        <div class="text">
          Télécharger l’application mobile Senseye afin de commander des lunettes parfaitement ajustées à votre visage et à votre vue.
        </div>
      </div>

      <div class="download-bottom">
        <img src="../../../public/assets/images/download.svg" width="150" />
        <div>
          *Disponible uniquement sur IOS, sur les smartphones et tablettes équipées de FaceID.
        </div>
      </div>

    </div>
    <div class="right">
      <div class="gradient"></div>
    </div>
  </div>
    
</template>

<script>

import emitter from 'tiny-emitter/instance'

export default {
  name: 'MegaMenuConcept',
  data() {
    return {
      displaySearch:false,
    }
  },
  watch:{
    
  },
  async mounted(){
    
  },
  methods:{
    hideMegaMenu() {
      emitter.emit('hide-megamenus',null)
    }
  },

}
</script>
<style scoped>
.download-bottom img {
  width:250px;
  margin-right:10px;
}
.download-bottom {
  display: flex;
  font-size:12px;
  max-width:400px;
  align-items: flex-end;
}
.text {
  font-size:14px;
  max-width:70%;
}
.megamenu .link
{
  font-size:18px;
  text-decoration: none;
  margin-top:15px;
}
.megamenu .strong
{
  font-size:28px;
  margin-bottom: 20px;
  font-weight:500;
}
.gradient {
  position:absolute;
  width:100%;
  height:100%;
  left:0;
  top:0;
  background: rgb(205,219,248);
  background: linear-gradient(90deg, rgba(205,219,248,1) 0%, rgba(217,210,243,1) 100%);
}
.megamenu
{
  padding:40px;
  width:100%;
  position:absolute;
  left:0;
  top:75px;
  z-index:9999999;
  display: flex;
  flex-direction: row;
  
  background:#fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  height:500px;
  animation: animmenu 0.3s linear 0s;
   overflow: hidden;
}
@keyframes animmenu {
  0% {
    height:0;
  }
  100% {
    height:500px;
  }
}
.megamenu .right
{
  width:50%;
  position:relative;
  min-height: 100%;
}
.megamenu .left
{
  width:50%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  position:relative;
}

</style>