<template>
  
  <div class="megamenu contenudesk">
    <div class="left">
      <div class="strong">
        ACCESSOIRES
      </div>
       <router-link @click="hideMegaMenu()" to="/accessoires" class="link">Tous les accessoires<span class="line-megamenu"></span></router-link>
      <a  @click="hideMegaMenu()" class="link">Pack de 3 chiffons microfibres<span class="line-megamenu"></span></a>
      <a @click="hideMegaMenu()" class="link">Pochette en cuir végétale<span class="line-megamenu"></span></a>
      <a  @click="hideMegaMenu()" class="link">Cartes cadeaux<span class="line-megamenu"></span></a>
  
    </div>
    <div class="right" v-if="accessories">
      
      <div class="product" v-for="accessorie in accessories.slice(0, 2)" :key="accessorie.id">
        <a :href="'/accessoire/'+accessorie.id">
          <img :src="accessorie.photos[0]" />
          <div class="name">{{accessorie.name}}</div>
          <div class="price">{{accessorie.price}}€</div>
        </a>
      </div>
      <div class="product">
        <img src="../../../public/assets/images/accessoire.jpg" />
        <div class="name">Pochette en cuir végétale</div>
        <div class="price">20€</div>
      </div>
    </div>
  </div>
    
</template>

<script>
import emitter from 'tiny-emitter/instance'
import {APIRequest} from "@simb2s/senseye-sdk"

export default {
  name: 'MegaMenuConcept',
  data() {
    return {
      accessories:null,
      photos:null,
      displaySearch:false,
    }
  },
  watch:{
    
  },
  async mounted(){
    await this.getAccessories()
  },
  methods:{
    hideMegaMenu() {
      emitter.emit('hide-megamenus',null)
    },
    async getAccessories()  {
        let accessories=await APIRequest.list('glasses',null,'accessoire')
        this.accessories=accessories[0]
    },
  },

}
</script>
<style scoped>
.product a {
  text-decoration: none !important;
}
.megamenu .link
{
  font-size:18px;
  text-decoration: none;
  margin-top:15px;
}
.megamenu .strong
{
  font-size:28px;
  margin-bottom: 20px;
  font-weight:500;
}
.megamenu
{
  padding:40px;
  width:100%;
  position:absolute;
  left:0;
  top:75px;
  z-index:9999999;
  display: flex;
  flex-direction: row;
  background:#fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  height:500px;
  animation: animmenu 0.3s linear 0s;
   overflow: hidden;
}
@keyframes animmenu {
  0% {
    height:0;
  }
  100% {
    height:500px;
  }
}
.product {
  width:45%;
  font-size:13px;
}
.megamenu .right
{
    width:50%;
    display:flex;
    justify-content:space-between ;
    align-items: center;
}
.megamenu .left
{
  width:50%;
}

</style>