<template>
            <div class="step-prescription" :class="{active:step==4, next:step==3}">
            <h3>Traitement de vos verres</h3>
            <template v-for="processing in processings" :key="processing.id">
            <div class="item" @click="closePrescription(processing.id, processing.price)">
              <span class="name">{{processing.name}}</span>
              <span class="decription">{{processing.description}}</span>
              <span class="price"><span v-if="processing.price>0">{{processing.price}}€</span><span v-else>Offert</span></span>
            </div>  
            <br>
          </template> 
          </div>
</template>

<script>
export default {
    name: "OptionTreatment",
    props: ["step","processings"],
    methods: {
        closePrescription(id,price)
        {
            this.$emit('closePrescriptionEv', id, price)
        }
    }

}
</script>

<style>
.item {
  cursor: pointer;
}
.item .description {
  margin-right: 10px;
}

.item .price {
  float: right;
}

.item .name {
  font-weight: 700;
  display: block;
}

</style>