import { defineStore } from 'pinia'
import { Auth } from "@simb2s/senseye-sdk";
import { useAuthStore } from './authStore';

export const useAccountStore = defineStore('account', {
    state: () => ({
        authStore: useAuthStore(),
        user: null,
        accountLoaded: false
    }),
    actions: {
        async getUser(){
            try{
                this.accountLoaded = false;
                this.user = await this.authStore.getUser();
              
            }catch(err){
                console.log(err);
            }finally{
                this.accountLoaded = true;
            }
        },
        async updateAccount(accountObj){
            try{
                this.accountLoaded = false;
                await Auth.update_account(localStorage.id_user_senseye_auth, accountObj)
            }catch(err){
                console.log(err);
            }finally{
                this.accountLoaded = true;
            }
        },
        resetStore(){
            this.user = null;
            this.accountLoaded = false;
        }

    }
 }
)