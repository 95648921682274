<template>
  <a href="#">
   <div class="header-bottom">
     <div class="left">
      <img src="../../../public/assets/images/download.svg" width="120" />    
     </div>
     <div class="text">
       <div class="marquee"  v-for="(i, index) in loop" :key="index" >
        <span class="marqueetext">Télécharger l’application IOS afin de bénéficier de l’ajustage sur-mesure de nos lunettes gratuitement<span class="space"></span></span>

        <!-- <span class="marqueetext">Télécharger l’application IOS afin de bénéficier de l’ajustage sur-mesure de nos lunettes gratuitement<span class="space"></span></span>
        <span class="marqueetext">Télécharger l’application IOS afin de bénéficier de l’ajustage sur-mesure de nos lunettes gratuitement<span class="space"></span></span> -->
      </div>
     </div>
   </div>
  </a>
</template>

<script>

import jQuery from 'jquery'

export default {
  components: {  },
  name: 'HeaderBottom',
  props: {
    // description: String,
    // title: String,
    // name: String
  },
  data() {
    return {
      loop: 2
    }
  },
  mounted(){
    jQuery(document).ready(function(){
      jQuery('.header-bottom .text .marquee').marquee({
            //duration in milliseconds of the marquee
            duration: 15000,
            //gap in pixels between the tickers
            gap: 50,
            //time in milliseconds before the marquee will start animating
            delayBeforeStart: 0,
            //'left' or 'right'
            direction: 'left',
            //true or false - should the marquee be duplicated to show an effect of continues flow
            duplicated: false
        });
       if(jQuery(window).width()>760)
       {
         
        jQuery('.header-bottom .text .marquee').mouseenter(function(){
          jQuery(this).addClass('pausing')
          jQuery('.header-bottom .text .marquee').marquee('pause');
          
        });
        jQuery('.header-bottom .text .marquee').mouseleave(function(){
          jQuery('.header-bottom .text .marquee').marquee('resume');
          jQuery(this).removeClass('pausing')
        });
       }

    });
  },
  methods:{
    
  }
}
</script>
<style scoped>
.marquee.pausing
{
  transform: translateX(-50px);
}
.marquee
{
  transition:all 3s ease-in-out;
  width:100%;
  transform:none;
}
.marquee__inner.pausing
{
  animation:none;
}

.marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
   
    /*animation: marquee 10s linear infinite;*/
    opacity: 1;
}
.header-bottom .text .marqueetext .space
{
  width:150px;
  display:inline-block;
}

@keyframes marquee {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}
.header-bottom .text
{
  font-size:14px;
  position:absolute;
  top:50%;
  text-align: center;
  right:0;
  white-space: nowrap;
  transform: translateY(-50%);
  max-width:calc(100% - 12rem - 120px);
  overflow: hidden;
  display: flex;

}
.header-bottom
{
  padding-left:6rem;
  padding-top:5px;
padding-bottom: 5px;
  position: relative;
  color:#fff;
  background:#000;
  width:100%;
}

</style>