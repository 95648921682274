// import sendinblue from 'sendinblue';
const sendinblue = require('sendinblue');

exports.identify = (userObj, isAuth) => {
    if (isAuth) {
        const { name, surname, id, email } = userObj;
        const properties = {
            'FIRSTNAME': name,
            'LASTNAME': surname,
            'id': id
        };
        sendinblue.identify(email, properties);
    } else {
        const email = 'user@guest.com';
        sendinblue.identify(email);
    }
}

exports.formatSendInBlueItems = async (items) => {
    let sib_items = []
    for (let i = 0; i < 1; i++) {
        if (i == 0) {
            let item_glass = {}
            item_glass.id_glass = items[i].glass.id
            item_glass.price = items[i].glass.price
            item_glass.name = items[i].glass.name
            item_glass.quantity = items[i].quantity
            item_glass.id = items[i].id
            sib_items.push(item_glass)
        }
    }
    return sib_items
}

exports.track = (event_name, event_data = null, properties = null) => {

    sendinblue.track(
        event_name, /*mandatory*/
        properties, /*optional*/
        event_data /*optional*/
        )
}