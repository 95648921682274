<template>
    <div class="top-left">
        <div class="social-line">
            <template v-for="item in itemsRow1" :key="item.id">
                <a target="_blank" :href="item.link"><img
                    :src="require(`../../../../public/assets/images/2021/04/${item.id}.svg`)"></a>
            </template>
            </div>
            <div class="social-line">
            <template v-for="(item,index) in itemsRow2" :key="item.id">
                <a v-if="index === 0" target="_blank" :href="item.link"><img
                    :src="require(`../../../../public/assets/images/2021/04/${item.id}.svg`)"></a>
                <a v-else></a>
            </template>
        </div>

    </div>
</template>

<script>
import socialsData from "../../../data/footer/socials_data";

export default {
    name: "FooterSocials",
    data(){
        const {items} = socialsData;
        const [a,b, ...c] = items;
        return{
            itemsRow1: [a,b],
            itemsRow2: [...c],
        }
    }
    
}
</script>

<style scoped>
#site-footer .footer-bottom .top-left .social-line a:first-child {
  border-right:1px solid #707070;
}
#site-footer .footer-bottom .top-left .social-line:first-child {
  border-bottom:1px solid #707070;
}
#site-footer .footer-bottom .top-left .social-line {
  display: flex;
  height:50%;
}

#site-footer .footer-bottom .top-left .social-line a {
  height:100%;
  width:50%;
  
  display:flex;
  align-items: center;
  justify-content: center;
}
#site-footer .footer-bottom .top-left .social-line a img {
  width:auto;
  height:40px;
}

</style>