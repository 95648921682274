export default {
    items: [
        {
            link:"howdoesitworks",
            txt:"Comment ça marche",
            txt_en:"How it works",
        },
        {
            link:"contact",
            txt:"Contact",
            txt_en:"Contact",
        },
        {
            link:"account",
            txt:"Mon compte",
            txt_en:"My account",
        },
        {
            link:"faq",
            txt:"FAQ",
            txt_en:"FAQ",
        }
        
    ]
}