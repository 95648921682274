<template>

  <div class="home">

     <HomeTopComponent />
     <HomeWhiteComponent />
     <HomeTutoDeskComponent :currentTuto="currentTuto" @displayTutoFn="displayTuto"/>
     <HomeTutoMobComponent :currentTuto="currentTuto" />
     <HomeCollectionComponent 
              :barWidth="barWidth" 
              :barPosition="barPosition" 
              :glasses="glasses" 
              :photos="photos"
              :selectedFilter="selectedFilter"
              @filterCollectionFn="filterCollection"
      />
     <HomeNoLimitComponent 
                :activeCustom="activeCustom" 
                :underlinePosition="underlinePosition" 
                :activeColor="activeColor"
                @selectCustomFn="selectCustom"
                @setColorFn="setColor"
                @pauseCustomFn="pauseCustom"
      />
      <HomeVisuComponent />
      <HomeCollaborationComponent />
      <HomeCreationComponent :average="average" />

  </div>
  <Footer  :displayModalMenu="displayModalMenu" />
 
</template>

<script>

import HomeTopComponent from '../components/children/Home/HomeTopComponent.vue';


export default {
  name: 'Home',
  components: {
 
    HomeTopComponent,
   
  },
  data() {
        return {
        customColor:0,
        customTexture:0,
        barWidth:25,
        currentTuto:0,
        activeCustom:'color',
        activeColor:0,
        average:4.5,
        loaded:false,
        barPosition:0,
        glasses:[],
        controller: null,
        tween: null,
        columns:1,
        scene: null,
        email:null,
        currentOption:'colors',
        showModal:false,
        currentImage:'',
        currentColor:'color0',
        showDevices:false,
        selectedFilter:'solar',
       
        underlinePosition:0,
        photos:[],
        productSliderOptions: {
            container: '.slider-products',
            mouseDrag: true,
            navigation:false,
            navPosition:'bottom',
           
            loop: false,
            items: 1.7,
            center:false,
            gutter: 20,
            swipeAngle: 45,
            nav:false,
            controls:true,
            
            startIndex:0,
            responsive: {
               
                760: {
                items: 4
                }

            },
        },
        slider_products:null,
        slider_tuto:null,
        tutoSliderOptions: {
                container: '.slider-tuto',
                mouseDrag: true,
                navigation:false,
                navPosition:'bottom',
                speed:1000,
                items: 2,
                loop:false,
                gutter: 20,
                center:false,
                swipeAngle: 45,
                nav:false,
                controls:true,
                prevButton:'#prev-tuto',  // Node reference
                nextButton: '#next-tuto',
                startIndex:0,
                responsive: {
                  760: {
                      items: 2.8
                  }
                },
            },
          
        slider_creation:null,
        slider_magazine:null,
        intervalid1:null,
        intervalid2:null
        }
    },
    props:["displayModalMenu"],
  methods:{
    pauseCustom() {
      clearInterval(this.intervalid2)
    },
    indexChangedTuto() {
      clearInterval(this.intervalid1)
      this.currentTuto=this.slider_tuto.getInfo().index-1
      this.todo()
    },
    selectCustom(custom){
          
            if(custom=='color')
            {
                this.underlinePosition=0;
            }
            if(custom=='texture')
            {
              this.underlinePosition=25;
            }
            else if(custom=='tint')
            {
              this.underlinePosition=50;
            }
           
            this.activeCustom=custom
        },
    todo: function(){  
        this.intervalid1 = setInterval(() => {
          let currenttuto=this.currentTuto
          currenttuto++
          if(currenttuto>3)
          {
            currenttuto=0
          }
            this.currentTuto=currenttuto;
            // Guard l-176 for debugging, remove if necessary
            if(!this.slider_tuto) return;
            this.slider_tuto.goTo(currenttuto)
        }, 5000);
    },
    startCustom: function(){  
        this.intervalid2 = setInterval(() => {
          let currentColor=this.activeColor
          currentColor++
          if(currentColor>6)
          {
            currentColor=0
          }
            this.activeColor=currentColor
        }, 5000);
    },
    setColor(color) {
      clearInterval(this.intervalid2)
      this.activeColor=color
      this.startCustom()
    },
    displayTuto(index) {
      clearInterval(this.intervalid1)
      this.currentTuto=index
      this.todo()
    },
    filterCollection(filter)
    {
        this.selectedFilter=filter
        if(filter=='solar')
        {
            this.barWidth=25
            this.barPosition=0
        }
        else
        {
            this.barWidth=27
            this.barPosition=25
        }
    },
  },
  async mounted()
  {
     this.slider_tuto = tns(
              this.tutoSliderOptions
          );
        this.todo()
        this.startCustom()
        this.slider_products = tns(
            this.productSliderOptions
        );

        this.slider_tuto.events.on('indexChanged', this.indexChangedTuto);
        
       

        jQuery(document).ready(function(){

          jQuery(window).mousemove(function(e){
              var element = document.getElementById('line1');
              var minY = element.offsetTop;
              var maxY = minY + element.offsetHeight;
              if(e.clientY >= minY && e.clientY <= maxY)
              {
                  jQuery('#line1').css('z-index','4');
              }
              else
              {
                  jQuery('#line1').css('z-index','2');
              }
              var element2 = document.getElementById('line2');
              var minY2 = element2.offsetTop;
              var maxY2 = minY2 + element2.offsetHeight;
              if(e.clientY >= minY2 && e.clientY <= maxY2)
              {
                  jQuery('#line2').css('z-index','4');
              }
              else
              {
                  jQuery('#line2').css('z-index','2');
              }
              var element3 = document.getElementById('line3');
              var minY3 = element3.offsetTop;
              var maxY3 = minY3 + element3.offsetHeight;
              if(e.clientY >= minY3 && e.clientY <= maxY3)
              {
                  jQuery('#line3').css('z-index','4');
              }
              else
              {
                  jQuery('#line3').css('z-index','2');
              }
              var element4 = document.getElementById('line4');
              var minY4 = element4.offsetTop;
              var maxY4 = minY4 + element4.offsetHeight;
              if(e.clientY >= minY4 && e.clientY <= maxY4)
              {
                  jQuery('#line4').css('z-index','4');
              }
              else
              {
                  jQuery('#line4').css('z-index','2');
              }
              if(e.clientY >= minY && e.clientY <= maxY)
              {
                  jQuery('#line5').css('z-index','4');
              }
              else
              {
                  jQuery('#line5').css('z-index','2');
              }    
              var element6 = document.getElementById('line6');
              var minY6 = element.offsetTop;
              var maxY6 = minY6 + element6.offsetHeight;
              if(e.clientY >= minY6 && e.clientY <= maxY6)
              {
                  jQuery('#line6').css('z-index','4');
              }
              else
              {
                  jQuery('#line6').css('z-index','2');
              }

              var element7 = document.getElementById('line7');

              var minY7 = element7.offsetTop;
              var maxY7 = minY7 + element7.offsetHeight;


              if(e.clientY >= minY7 && e.clientY <= maxY7)
              {
                  jQuery('#line7').css('z-index','4');
              }
              else
              {
                  jQuery('#line7').css('z-index','2');
              }
              var element8 = document.getElementById('line8');
              var minY8 = element8.offsetTop;
              var maxY8 = minY8 + element8.offsetHeight;
              if(e.clientY >= minY8 && e.clientY <= maxY8)
              {
                  jQuery('#line8').css('z-index','4');
              }
              else
              {
                  jQuery('#line8').css('z-index','2');
              }
          });

          });

          
          this.loaded=true
    }
  
}
</script>

<style>

.home {
   
  }

  
.tns-outer button{
  display: none;
}

#next-tuto
{
    position:absolute;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    z-index:3;
}
#next-tuto img
{
    transform:rotate(-180deg) ;
}
#prev-tuto
{
    position:absolute;
    top:50%;
    left:10px;
    z-index:3;
    transform: translateY(-50%);
}

/* .slider
{
    position: relative;

} */


/* body .tns-nav button.tns-nav-active
{
height:3px;
}
body .tns-nav
{
    position:relative;
    top:-50px;
}
body .tns-nav button
{
width:45px !important;
border-radius:0;
height:2px;
}

.section-white .tns-outer button {
  display:none !important;
}
body .tns-nav button
{
  background:#868686;
  opacity: 1;
}
body .tns-nav button.tns-nav-active {
  background:#FFFFFF !important;
} */
</style>
