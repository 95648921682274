import { defineStore } from 'pinia'
import { APIRequest } from "@simb2s/senseye-sdk";
// import { useAuthStore } from './authStore';
// import extractData from '../utils/extractor/extract';
// import removeQuantity from '../utils/arrays/removeQuantity';

// import PiniaStore
import { useOrderItemStore } from './orderItemStore';


export const useCartStore = defineStore('cart', {
  state: () => ({
    isAuth: localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth ? true : false,
    cart: null,
    cartID: "",
    cartCopy: null,
    cartItemsCopy: [],
    // cartItemFromStorage: localStorage.cart_items ? JSON.parse(localStorage.cart_items) : [],
    useOrderItem: useOrderItemStore(),
    cartItemStorage: localStorage.cart_items,
    cartLoaded: false,
    alreadyInCart: false,
  }),
  actions: {
    async getCart() {
      const existingCartIDInStorage = (localStorage.cart_senseye_id != "" && localStorage.cart_senseye_id != null && localStorage.cart_senseye_id != undefined);
      if (!this.isAuth && (!existingCartIDInStorage)) {
      
        await this.createCart();
      }
      else if(!this.isAuth && existingCartIDInStorage) {
        
        await this.findCartNoAuth();
        
      }else if(this.isAuth){
        
        if(existingCartIDInStorage) {
          await this.findCartNoAuth();
        }
        else {
          await this.findCart();
        }
        
      }
    },
    async findCart(){
      console.log('FIND CARTTTTTTTTTTTTTTTTTT')
      try{
        this.cartLoaded = false;
        let carts = await APIRequest.list('carts',localStorage.id_user_senseye_auth);
        let cart_list = carts[0]
        
        if(cart_list.length > 0)
        {
          
          const cartI = await APIRequest.detail('carts',cart_list[0].id);
          this.cart = cartI;
          
          this.cartCopy = { ...this.cart };
          this.cartItemsCopy = this.cartCopy.cart_items;
          localStorage.cart_senseye_id = this.cart.id;
          // UNCOMMENT AFTER TESTING
          // localStorage.cart_items = JSON.stringify(this.cart.cart_items);
          this.cartID = this.cartCopy.id;
          
          await this.useOrderItem.updateOrderItemsFromCart()
        }else{
          await this.createCart();
        }
      }catch(err){
        console.log(err);
      }finally{
        this.cartLoaded = true;
      }
    },
    async applyPromotion(promotion){
      try{

        // let cart_items_new=[]

        // for(let j=0;j<this.useOrderItem.cartItem.length;j++)
        // {
        //   let item = this.useOrderItem.cartItem[j]
        //   delete item.id
        //   delete item.cart
        //   item.glass={id:this.useOrderItem.cartItem[j].glass.id}
          
        //   cart_items_new.push(item)

        // }
      
        let Parameters = {};
        Parameters.amount = this.cartCopy.amount;
        //Parameters.cart_items = cart_items_new;
        Parameters.promotion = {}
        Parameters.promotion.id=promotion
        Parameters.user=this.cartCopy.user;


        console.log('update cart item promo store')
        console.log(Parameters)

        let cart = await APIRequest.update('carts', this.cartCopy.id, JSON.stringify(Parameters))

       
        this.cart = cart;
        this.cartCopy.promotion = cart.promotion;

      }catch(err){
        console.log(err);
      }finally{
        return this.cartCopy
      }
    },
    async unApplyPromotion(){
      try{

        // let cart_items_new=[]

        // for(let j=0;j<this.useOrderItem.cartItem.length;j++)
        // {
        //   let item = this.useOrderItem.cartItem[j]
        //   delete item.id
        //   delete item.cart
        //   item.glass={id:this.useOrderItem.cartItem[j].glass.id}
          
        //   cart_items_new.push(item)

        // }
      
        let Parameters = {};
        Parameters.amount = this.cartCopy.amount;
        //Parameters.cart_items = this.useOrderItem.cartItem;
        Parameters.promotion = null
        Parameters.user=this.cartCopy.user;
        let cart = await APIRequest.update('carts', this.cartCopy.id, JSON.stringify(Parameters))
        this.cart = cart;
       this.cartCopy = this.cart;

      }catch(err){
        console.log(err);
      }finally{
        return this.cartCopy
      }
    },
    async findCartNoAuth(){
      console.log('FIND NO AUTHHHHHHHhhhh')
      try{
  
        this.cartLoaded = false;
        let cart = await APIRequest.detail('carts', localStorage.cart_senseye_id);
        if(cart) {
          this.cart = cart;
          this.cartCopy = {...this.cart};
          this.cartItemsCopy = this.cartCopy.cart_items;
        
          //await this.useOrderItem.updateOrderItemsFromCart(localStorage.cart_senseye_id)
        }
        else {
          this.createCart()
        }
        
      }catch(err){
        console.log(err);
      }finally{
        this.cartLoaded = true;
      }
    },
    updateCartAmount(amount)
    {
      this.cartCopy.amount=amount
    },
    async createCart() {
    
      try {
        // Possible to set default Value when creating a new Cart ?
        let Parameters = {
          amount: 0,
          delivery_amount: 0,
          delivery_method: 0,
          
        }
        if (this.isAuth) {
          Parameters.user={}
          Parameters.user.id = localStorage.id_user_senseye_auth;
        }
        this.cart = await APIRequest.create('carts', Parameters);
        this.cartCopy = { ...this.cart };
        this.cartItemsCopy = this.cartCopy.cart_items;
        //  await this.reloadCartItems()
        localStorage.cart_senseye_id = this.cart.id;
        localStorage.cart_items = JSON.stringify([]);
        this.cartID = this.cart.id;
        //  await emitter.emit('cart-created');
        //  document.location.reload()
      } catch (err) {
        console.log(err);
      } finally {
        this.cartLoaded = true;
      }
    },
    async unAssignCurrentCartAfterLogout(){
    
        console.log('unassign cart after logout')
        try {
          this.cartLoaded = false;

          let Parameters = this.cartCopy
          Parameters.user = null;

          console.log('params')
          console.log(Parameters)
          delete Parameters.cart_items
          await APIRequest.update('carts', this.cartCopy.id, Parameters);

         
         

          

          if(this.useOrderItem.cartItem.length>0) {
            let cart_items = [];
            let current_cart_item;
            for(let j=0;j<this.useOrderItem.cartItem.length;j++) {
              
              current_cart_item=this.useOrderItem.cartItem[j];

              if(current_cart_item.scan!=null)
               {
                let new_cart_item={scan:null}
                console.log('params')
                console.log(new_cart_item)
                await APIRequest.update('order-items', current_cart_item.id, new_cart_item);
               }

         
            }
            
           
          }

         
          await this.getCart();
        } catch (err) {
          console.log(err);
        } finally {
          this.cartLoaded = true;
        }
      
    },
    async assignCurrentCartAfterLogin(user){
     
      if(this.useOrderItem.cartItem.length>0) {
        try {
          let Parameters = this.cartCopy
          if(localStorage.id_user_senseye_auth)
           {
            Parameters.user = {};
            Parameters.user.id=parseInt(localStorage.id_user_senseye_auth)
           }
          
          this.cartLoaded = false;
          //Parameters.cart_items=this.useOrderItem.cartItem
          delete Parameters.cart_items
          await APIRequest.update('carts', this.cartCopy.id, Parameters);
          await this.getCart();
        } catch (err) {
          console.log(err);
        } finally {
          this.cartLoaded = true;
        }
      }
      else {
        await this.getCart()
      }
    },

    async deleteFromSocket() {
      await this.resetStore()
      await this.useOrderItem.resetStore()
      localStorage.order_items=[]
      await this.getCart()
      
    },
    resetStore() {
        console.log('RESET CART STORE')
        this.isAuth = false,
        this.cart = null,
        this.cartID = "",
        this.cartCopy = null,
        this.cartItems = [],
        this.cartLoaded = false
    }
  },
})