import { defineStore } from 'pinia';
import { APIRequest } from "@simb2s/senseye-sdk";

export const useGlassStore = defineStore('glass', {
    state: () => ({
        isAuth: localStorage.token_senseye_auth && localStorage.refresh_token_senseye_auth ? true : false,
        glass: null,
        glassLoaded: false
    }),
    actions: {
        async getSingleGlass(id){
            try{
                this.glassLoaded = false;
                this.glass = await APIRequest.detail('glasses',id);
                return this.glass;
            }catch(err){
                console.log(err);
            }finally{
                this.glassLoaded = true;
            }
        },
        resetStore(){
            this.isAuth = false;
            this.glass = null;
            this.glassLoaded = false;
        }

    }
 }
)