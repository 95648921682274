const formatObjectCollab = (product, variation, cart) => {

    let Parameters= {};

    Parameters.amount = product.price;
    Parameters.quantity = 1;
    Parameters.glass = {};
    Parameters.glass.id = product.id;
    Parameters.cart = {};
    Parameters.cart.id = cart.id;
    Parameters.product_variation_item = {};
    Parameters.product_variation_item.id = variation;

    return Parameters;
}

module.exports = formatObjectCollab;