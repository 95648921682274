<template>
    
    <div v-if="open" class="download-cart">
        <div class="content">
            <img  src="../../../../public/assets/images/download.svg" />
            <div class="text">Télécharger l’application IOS afin d’ajuster la monture de votre choix à votre visage, gratuitement. Nous scannons votre visage avec une technologie unique. Télécharger ici</div>
            

           
        </div>
        <img src="../../../../public/assets/home/cross.svg" class="cross" @click="closeModal" />
    </div>
</template>

<script>


export default {
    name: "CartDownloadComponent",
    props: [],
            components: {
              
  },
    methods: {

        closeModal()
        {
           this.open=false
            
        },
        
                
    },
    data(){
      
        return{
            open:true
        }
    },
    computed: {
    
    
    },
}
</script>

<style scoped>
.download-cart .cross {
    position:absolute;
    width:25px;
    right:-5px;
    top:-10px;
}
.download-cart .content {
    display:flex;
    color:#fff;
    font-size:12px;
}
.download-cart .text {
    padding-left:30px;
}
.download-cart
{
    position:fixed;
    width:calc(50vw - 40px);
    bottom:165px;
    z-index: 3;
    padding:20px 30px;
    background: transparent linear-gradient(90deg, rgba(192, 192, 192, 1) 0%, rgba(0, 0, 255, 0.9) 100%) 0% 0% no-repeat padding-box;
    border-radius: 13px;
}
</style>