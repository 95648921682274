<template>
              <div class="top-right">

                <template v-for="item in items" :key="item.link">
                    <a style="cursor:pointer" v-if="item.link.startsWith('http')" :href="item.link">
                      <span v-if="language=='fr'">{{ item.txt }}</span>
                      <span v-else>{{ item.txt_en }}</span>
                    <span class="line-footer"></span></a>
                    <a style="cursor:pointer" v-else @click="$router.push({name: item.link})">
                      <span v-if="language=='fr'">{{ item.txt }}</span>
                      <span v-else>{{ item.txt_en }}</span>
                    <span class="line-footer"></span></a>
                </template>


</div>
</template>

<script>
import topRightMenu from '../../../data/footer/top_right_menu';

export default {
    name: "FooterTopRight",
    props:["language"],
    data(){
        const {items} = topRightMenu
        return{
            items
        }
        
    }
}
</script>

<style scoped>
#site-footer .footer-bottom .top-right {
  width:40%;
  padding:10%;
  text-align: left;
  display:flex;
  border-left:1px solid #707070;
  flex-direction: column;
  align-content: space-between;
  align-items:normal;
  justify-content: space-around;
}
#site-footer .footer-bottom .top-right a
{
  display: block;
  margin-bottom:7px;
    color: #fff;
    font-size: 22px;
  font-weight: 600;
}

</style>