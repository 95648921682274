<template>
    <div id="cart-total" v-if="displayCartTotal">
        <content-loader v-if="loaded == false || cartTotalLoaded==false" viewBox="0 0 410 50" primaryColor="#f3f3f3" secondaryColor="#cccccc">
            <rect x="0" y="0" rx="25" ry="25" width="150" height="50" />
            <rect x="250" y="0" rx="25" ry="25" width="150" height="50" />
        </content-loader>
        <div v-else>
            <span class="label">
                <b>Total</b>&nbsp;
                <span class="total_cart">
                    <span id="total_cart">{{ totalAmount }}</span>€
                </span>
            </span>
            <div class="purchase">
                <a  v-if="language=='fr'" class="button-purchase" @click="proceedCheckout()">Commander<span class="little">Paiement & Livraison</span></a>
                <a  v-else class="button-purchase" @click="proceedCheckout()">Checkout<span class="little">Payment & Delivery</span></a>
            </div>
            <br /><br />
            
            <span class="delivery" v-if="language=='fr'">
                <br />Livraison :
                <span id="delivery_text">
                    <span >6 à 8</span>
                </span>
                semaines
            </span>
            <span class="delivery" v-else>
                <br />Delivery:
                order manufacturing between 6 and 8 weeks
            </span>
            
            <div class="card-icons">
                <template v-for="(processor,index) in processors" :key="index">
                    <img :src="require(`../../../../public/assets/images/${processor}.svg`)" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import payment_processors from '../../../data/cart/payment_processors';

export default {
    name: "CartTotal",
    props: ["displayCartTotal", "loaded", "cart", "onlycollab", "totalAmount","cartTotalLoaded","language"],
    data(){
        const {processors} = payment_processors
        return{
            processors 
        }
    },
    methods:{
        async proceedCheckout() {
            await this.$emit('proceedCheckoutEv')
        }
    }

}
</script>

<style scoped>
.button-purchase {
    cursor: pointer;
}
#cart-total .label
{
  position: relative;
  top:10px;
}

.delivery
{
  font-size:11px;
  position:relative;
  top:-35px;
}

.card-icons 
{
  float:right;
}
.card-icons img
{
  margin-left:5px;
  width:49px;
  display: inline-block;
  vertical-align: top;
}

</style>