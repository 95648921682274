<template>
    <div id="popup-app" @click="closeModal($event)" class="popup-login">
			<div class="content">
				<span id="cross" @click="closePopup()"><img src="../../../public/assets/images/2021/05/iconfinder_Close_1031533-1.png" width="12" /></span>
        <h4>Comment ça marche ?</h4>
        
      </div>
		</div>
</template>


<script>
import {Auth} from "@simb2s/senseye-sdk";
import jQuery  from 'jquery';
import emitter from 'tiny-emitter/instance'

export default {
  components: {  },
  name: 'PopupHowItWork',
  data() {
    return {
     
    }
  },
  
  async mounted(){

  },
  methods:{
   
   closePopup()
    {
     
        emitter.emit('close-howitwork-popup')
      
    },
    closeModal($event)
    {
      if (jQuery($event.target).parents('.content').length) {

      }
      else
      {
        emitter.emit('close-howitwork-popup')
      }
    },
    
    
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
.register-form input,.login-form input
{
  margin-bottom:20px;
}
#popup-app
{
z-index: 9999999;
    position: fixed;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0vh;
    }
    #popup-app .content
    {
      background:#fff;
      position:absolute;
      left:50%;
      text-align: center;
      top:50%;
      padding:20px;
      transform: translate(-50%,-50%);
    }
    #popup-app .content .left
    {
      display: inline-block;
      width:49%;
      vertical-align: top;
    }
    #popup-app .content .right
    {
      color:rgba(3, 1, 1, 0.51);
      display: inline-block;
      width:49%;
      vertical-align: top;
      padding:20px;
      text-align: left;
    }
    #popup-app #cross
    {
      cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

    
</style>
